import axios from 'axios';

const apiElasticsearch = axios.create({
  baseURL: process.env.REACT_APP_BM_URL,
  params: {
    Authorization:
      'eyJhbGciOiJIUzI1NiJ9.eyJyZXF1ZXN0ZXIiOiJ0cmVuZHMiLCJpc3MiOiJidXp6LW1vbml0b3ItYXV0aCIsImlhdCI6MTYwMTkxMzUzOH0.yT3G3uQC3ANJHuEEpipZvOuW58IxLtbzCJwb-MPyFCE',
  },
});

export default apiElasticsearch;
