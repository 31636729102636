import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import InstaLogo from 'assets/images/insta-logo.png';
import { CloudDownloadOutlined, InstagramFilled } from '@ant-design/icons';
import { Menu, Dropdown, Skeleton, Row, Col } from 'antd';
import { useAuth } from 'hooks/auth';
import ActiveCampaign from 'components/Modals/ActiveCampaign';
import DownloadModal from 'components/Modals/Download';
import ImportModal from 'components/Modals/Import';
import { uuid } from 'uuidv4';
import SectionTitle from 'components/SectionTitle';
import usePostsContainer from 'hooks/postsContainer';
import { Container, Header } from './styles';
import PostContainer from '../PostContainer';

const PostMenu: React.FC<{ handleMenu?: Array<() => void> }> = ({
  handleMenu,
}) => {
  const intl = useIntl();

  return (
    <Menu>
      <Menu.Item onClick={handleMenu && handleMenu[0]}>
        {intl.formatMessage({ id: 'posts.button.download' })}
      </Menu.Item>
      <Menu.Item onClick={handleMenu && handleMenu[1]}>
        {intl.formatMessage({ id: 'posts.button.import' })}
      </Menu.Item>
    </Menu>
  );
};

const TopPostsInstagramHeader: React.FC<{
  handleMenu?: Array<() => void>;
}> = ({ handleMenu }) => {
  const intl = useIntl();

  return (
    <Header>
      <img src={InstaLogo} alt="Instagram" />
      <SectionTitle>
        {intl.formatMessage({ id: 'posts.topposts' })}
      </SectionTitle>
      <Dropdown
        trigger={['hover', 'click']}
        overlay={<PostMenu handleMenu={handleMenu} />}
      >
        <CloudDownloadOutlined size={24} />
      </Dropdown>
    </Header>
  );
};

const TopPostsInstagram: React.FC = () => {
  const { user } = useAuth();
  const [activeCampaign, setActiveCampaign] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [importModal, setImportModal] = useState(false);

  const { paramsDatas, maxImport, loading, searchedTerms } = usePostsContainer(
    '/historical_posts.json',
    {
      service: ['instagram'],
      size: 28,
      sort_by: 'top_posts_field',
    },
  );

  const handleImportModal = useCallback(
    () =>
      user.consumer_type && user.consumer_type !== 'unassigned_costumer'
        ? setImportModal(true)
        : setActiveCampaign(true),
    [user.consumer_type],
  );

  const handleDownloadModal = useCallback(
    () =>
      user.consumer_type && user.consumer_type !== 'unassigned_costumer'
        ? setDownloadModal(true)
        : setActiveCampaign(true),
    [user.consumer_type],
  );

  const handleMenu = useMemo(() => [handleDownloadModal, handleImportModal], [
    handleDownloadModal,
    handleImportModal,
  ]);

  const PostLoading = useCallback(
    props => (
      <Row gutter={[16, 8]}>
        {[1, 2, 3, 4].map(() => (
          <Col xs={24} sm={12} lg={6} key={uuid()}>
            <Skeleton
              loading={props.show}
              title={false}
              paragraph={false}
              active
              avatar={{
                shape: 'square',
                style: {
                  width: '100%',
                  height: '251.5px',
                },
              }}
            />
            <Skeleton loading={props.show} active />
          </Col>
        ))}
      </Row>
    ),
    [],
  );

  return (
    <Container title={<TopPostsInstagramHeader handleMenu={handleMenu} />}>
      {paramsDatas &&
        paramsDatas.size >= 1 &&
        searchedTerms.map(item => {
          const key = item.obligatory;
          const value = paramsDatas.get(key);
          return value ? (
            <PostContainer
              socialIcon={<InstagramFilled style={{ color: '#cd2e92' }} />}
              title={paramsDatas.size > 1 ? key : ''}
              key={key}
              data={value}
            />
          ) : null;
        })}
      <PostLoading show={loading} />
      <ActiveCampaign
        visible={activeCampaign}
        onCancel={() => setActiveCampaign(false)}
      />
      <DownloadModal
        service="instagram"
        visible={downloadModal}
        onCancel={() => setDownloadModal(false)}
      />
      <ImportModal
        service="instagram"
        visible={importModal}
        onCancel={() => setImportModal(false)}
        maxImportNumber={maxImport}
      />
    </Container>
  );
};

export default TopPostsInstagram;
