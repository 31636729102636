import styled from 'styled-components';

import { Card } from 'antd';

export const Container = styled(Card)`
  grid-area: shares;
  div.ant-card-body {
    div.ant-skeleton-header {
      padding: 0;
      display: grid;
      place-items: center;
    }
    div.echarts-for-react {
      width: 100%;
      display: grid;
      place-items: center;

      @media (max-width: 970px) {
        height: 600px !important;
        canvas,
        div {
          height: 600px;
        }
      }
      @media (max-width: 800px) {
        height: 900px !important;
        canvas,
        div {
          height: 900px;
        }
      }
    }
    div.ant-col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
