import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Typography, message } from 'antd';

import { useSearch } from 'hooks/search';

import * as Yup from 'yup';
import formatQuery from 'utils/formatQuery';
import countOperators from 'utils/countOperators';
import Input from './Input';
import MultipleSelect from './MultipleSelect';
import RangeDatePicker from './RangeDatePicker';

import Select from './Select';
import { Form, Card } from './styles';

const SearchForm: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const {
    params,
    preview,
    booleanMode,
    setParams,
    handleObligatoryChange,
  } = useSearch();

  const handleSubmit = useCallback(async () => {
    try {
      const { date, obligatory, optional, banned } = params;

      const formValidationFormat = {
        terms: formatQuery({ obligatory, optional, banned }),
        operators: countOperators(
          formatQuery({ obligatory, banned, optional }),
        ),
      };

      const schema = Yup.object().shape({
        terms: Yup.string().max(
          512,
          intl.formatMessage({ id: 'searchform.error.maxsize' }),
        ),
        operators: Yup.number().max(
          15,
          intl.formatMessage({ id: 'searchform.error.maxoperators' }),
        ),
      });

      await schema.validate(formValidationFormat, {
        abortEarly: false,
      });

      const urlParams = new URLSearchParams({
        obligatory: params.obligatory,
        optional: params.optional,
        banned: params.banned,
        languages: JSON.stringify(params.languages),
        dateType: String(date.type),
        dateRange: JSON.stringify([date.start, date.end]),
      }).toString();
      history.push(`/results?${urlParams}`);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        message.error(error.message);
      }
    }
  }, [history, intl, params]);

  return (
    <Card>
      <Form onFinish={handleSubmit}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={16}>
            <Input
              label={intl.formatMessage({ id: 'searchform.allwords' })}
              value={params.obligatory}
              onChange={e => handleObligatoryChange(e.target.value)}
              maxLength={300 - (params.optional.length + params.banned.length)}
              booleanMode={booleanMode}
            />
            <Input
              label={intl.formatMessage({ id: 'searchform.somewords' })}
              value={params.optional}
              onChange={e => setParams({ ...params, optional: e.target.value })}
              maxLength={
                300 - (params.obligatory.length + params.banned.length)
              }
            />
            <Input
              label={intl.formatMessage({ id: 'searchform.nonewords' })}
              value={params.banned}
              onChange={e => setParams({ ...params, banned: e.target.value })}
              maxLength={
                300 - (params.optional.length + params.obligatory.length)
              }
            />
          </Col>
          <Col xs={24} md={8}>
            <MultipleSelect
              label={intl.formatMessage({ id: 'searchform.language' })}
            />
            <Select label={intl.formatMessage({ id: 'searchform.period' })} />
            {params.date.type === 'personalized' && (
              <RangeDatePicker
                label={intl.formatMessage({ id: 'searchform.date-range' })}
              />
            )}
          </Col>
          <Col
            xs={24}
            md={16}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography.Paragraph
              ellipsis={{ rows: 1, expandable: true }}
              style={{ minHeight: '22px', width: '100%', margin: '0' }}
            >
              {preview}
            </Typography.Paragraph>
          </Col>
          <Col xs={24} md={8}>
            <Button
              block
              type="primary"
              htmlType="submit"
              disabled={
                !params.obligatory && !params.optional && !params.banned
              }
            >
              {intl.formatMessage({ id: 'searchform.button' })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default SearchForm;
