import styled from 'styled-components';
import { Card } from 'antd';

export const Container = styled(Card)`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 32px;
  .ant-card-body {
    padding: 0;
  }
  div.ant-card-head-title {
    padding: 16px 0px;
  }
  header {
    display: flex;
    align-items: center;
    button {
      margin-left: auto;
      & + button {
        margin-left: 16px;
      }
    }
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      button {
        margin: 8px 0;
        width: 100%;
        & + button {
          margin-left: 0px;
        }
      }
    }
  }
`;

export const ContainerAddTerm = styled(Container.Grid)`
  flex-grow: 1;
  padding: 12px;
  min-width: 200px;
  width: min-content;
`;
