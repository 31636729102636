import axios, { AxiosResponse } from 'axios';

export class TwitterService {
  private static instance = axios.create({
    baseURL: process.env.REACT_APP_TWITTER_TRENDS_API,
  });

  static getTrendsByCoutry(country: string): Promise<AxiosResponse> {
    return TwitterService.instance.get(country);
  }

  static exportTrends(
    emails?: Array<string>,
    period?: { start: string; end: string },
    country?: string,
  ): Promise<AxiosResponse> {
    const { start, end } = period || {};
    const formattedEmails = emails ? emails.join(',') : '';

    return TwitterService.instance.get('export', {
      params: { emails: formattedEmails, start, end, country },
    });
  }
}
