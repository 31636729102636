import React, { useMemo } from 'react';

import { useIntl } from 'react-intl';
import { Metric } from 'hooks/search';
import { Empty } from 'antd';
import { map } from 'lodash';
import EvolutionChart from './EvolutionChart';
import { Container } from './styles';

interface Data {
  name: 'Instagram' | 'Facebook' | 'X' | 'Total';
  value: number[] | undefined;
}

interface TermData {
  facebook: Metric[];
  twitter: Metric[];
  instagram: Metric[];
}

interface TermEvolutionWrapperProps {
  title: string;
  data: TermData;
  total: boolean;
}

const TermEvolutionWrapper: React.FC<TermEvolutionWrapperProps> = ({
  data,
  title,
  total,
}) => {
  const intl = useIntl();

  const dataByNetwork: Data[] = useMemo(
    () => [
      {
        name: 'Instagram',
        value: map(data.instagram, 'value'),
      },
      {
        name: 'Facebook',
        value: map(data.facebook, 'value'),
      },
      {
        name: 'X',
        value: map(data.twitter, 'value'),
      },
    ],
    [data],
  );

  const XAxis = useMemo(
    (): string[] =>
      Object.values(data)
        .find((arr: []) => arr.length > 0)
        .map((item: Metric) => {
          const label = item.label.slice(4, 8);
          return [label.slice(2, 4), label.slice(0, 2)].join('/');
        }),
    [data],
  );

  const totalValues: Data = useMemo(
    () => ({
      name: 'Total',
      value: XAxis.map(
        (_, index) =>
          (data.facebook ? data.facebook[index].value : 0) +
          (data.instagram ? data.instagram[index].value : 0) +
          (data.twitter ? data.twitter[index].value : 0),
      ),
    }),
    [XAxis, data],
  );

  const isDataValid = useMemo(() => {
    return (
      !!data.facebook?.find(metric => metric.value !== 0) ||
      !!data.instagram?.find(metric => metric.value !== 0) ||
      !!data.twitter?.find(metric => metric.value !== 0)
    );
  }, [data]);

  return (
    <Container>
      {data && isDataValid ? (
        <>
          {total ? (
            <EvolutionChart
              XAxis={XAxis}
              title={title}
              legend={['Total']}
              data={[
                { name: 'Total', value: undefined },
                { name: 'Total', value: undefined },
                totalValues,
              ]}
            />
          ) : (
            <EvolutionChart
              XAxis={XAxis}
              title={title}
              legend={map(dataByNetwork, 'name')}
              data={dataByNetwork}
            />
          )}
        </>
      ) : (
        <Empty
          description={intl.formatMessage({ id: 'posts.no-data-found' })}
        />
      )}
    </Container>
  );
};

export default TermEvolutionWrapper;
