import styled from 'styled-components';
import { Input } from 'antd';

export const InputANT = styled(Input)`
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
