import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './auth';
import { I18nProvider } from './i18n';

import buzzmonitorTheme from '../styles/theme/buzzmonitor';
import { SearchProvider } from './search';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={buzzmonitorTheme}>
      <AuthProvider>
        <SearchProvider>
          <I18nProvider>{children}</I18nProvider>
        </SearchProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
