import styled from 'styled-components';
import { Card } from 'antd';

export const Container = styled(Card)`
  grid-area: topPostTwitter;
  border-top: 4px solid black !important;
  div.ant-card-body {
    div.ant-skeleton-header {
      padding: 0;
    }
    button {
      margin-top: 16px;
      color: black;
      border-color: black;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  h4 {
    margin-right: auto;
  }
  img {
    height: 25px;
    margin-right: 16px;
  }
  svg {
    color: black;
    height: 32px;
    width: auto;
  }
`;
