import styled from 'styled-components';
import { Card } from 'antd';

export const Container = styled(Card)`
  grid-area: topPostFB;
  border-top: 4px solid #1877f2 !important;
  div.ant-card-body {
    div.ant-skeleton-header {
      padding: 0;
    }
    button {
      margin-top: 16px;
      color: #1877f2;
      border-color: #1877f2;
    }
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  h4 {
    margin-right: auto;
  }
  img {
    height: 32px;
    width: 32px;
    margin-right: 16px;
  }
  svg {
    color: #1877f2;
    height: 32px;
    width: 32px;
  }
`;
