import styled from 'styled-components';

import { Card } from 'antd';

export const Container = styled(Card)`
  grid-area: gender;
  div.ant-card-body {
    div.ant-skeleton-header {
      padding: 0;
      display: grid;
      place-items: center;
    }
  }
`;

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
