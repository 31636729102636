import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  max-width: 1080px;
  width: 100%;
  padding: 57px 0px 32px;
  margin: 0 auto;

  main {
    max-width: 1080px;
    width: 100%;
    display: grid;

    grid-template-areas:
      'terms terms'
      'shares shares'
      'gender sentiment'
      'topPostFB topPostFB'
      'topPostInsta topPostInsta'
      'topPostTwitter topPostTwitter'
      'topInfluencers topInfluencers';

    grid-gap: 32px;

    > div {
      max-width: 100%;
    }
  }

  @media (max-width: 1080px) {
    padding: 57px 20px 0;
    main {
      grid-template-columns: repeat(1fr);
      padding: 0px 20px 0;
    }
  }
  @media (max-width: 480px) {
    padding: 57px 8px 0;
    main {
      grid-template-columns: repeat(1fr);
      padding: 0px 8px;
    }
  }
`;
