import { useState, useEffect, useCallback } from 'react';
import { useSearch, Params, QueryBuilderData, IPost } from 'hooks/search';
import { difference, map } from 'lodash';
import elasticsearchApi from 'services/elasticsearchApi';

export default function usePostsContainer(
  requestURL: string,
  requestBody: QueryBuilderData,
): {
  paramsDatas: Map<string, IPost[]>;
  loading: boolean;
  searchedTerms: Params[];
  maxImport: number;
} {
  const { queryBuilder, params, searchedTerms, comparisonTerms } = useSearch();
  const [loading, setLoading] = useState(false);
  const [paramsDatas, setParamsDatas] = useState<Map<string, IPost[]>>(
    new Map(),
  );
  const [maxImport, setMaxImport] = useState(0);

  /*
    Check if some term was removed from searchedTerms and remove its data from 
    paramsDatas, removing the post container of term.
  */
  useEffect(() => {
    if (searchedTerms.length < paramsDatas.size) {
      const keyToRemove = difference(
        Array.from(paramsDatas.keys()),
        map(searchedTerms, 'obligatory'),
      );
      setParamsDatas(prevDatas => {
        prevDatas.delete(keyToRemove?.[0]);
        return new Map(prevDatas);
      });
    }
  }, [paramsDatas, searchedTerms]);

  const apiRequest = useCallback(
    async (url, body) => {
      setLoading(true);
      const term = body.customParams
        ? body.customParams.obligatory
        : params.obligatory;
      await elasticsearchApi
        .post<{ data: IPost[]; count: number }>(url, queryBuilder(body))
        .then(response => {
          if (response?.data?.data && response.data.data.length > 0) {
            if (searchedTerms.length === 1) {
              setMaxImport(response.data.count);
            }
            setParamsDatas(
              prevDatas =>
                new Map(prevDatas.set(term, [...response.data.data])),
            );
          } else {
            setParamsDatas(prevDatas => new Map(prevDatas.set(term, [])));
          }
        })
        .finally(() => setLoading(false));
    },
    [params.obligatory, queryBuilder, searchedTerms.length],
  );

  /*
    Searches for the term set in the params.
  */
  const requestData = useCallback(() => {
    if (params.obligatory || params.optional || params.banned) {
      apiRequest(requestURL, requestBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /*
    Calls requestData method always when it gets updated, which means that 
    always when params changes requestData is called.
  */
  useEffect(() => {
    requestData();
  }, [requestData]);

  /*
    This effect is used to search for the comparison terms when the page is 
    loaded for the first time.
  */
  useEffect(() => {
    if (comparisonTerms.length > 0) {
      comparisonTerms.forEach(term => {
        apiRequest(requestURL, { ...requestBody, customParams: term });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonTerms]);

  return { paramsDatas, loading, searchedTerms, maxImport };
}
