import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useIntl, IntlShape } from 'react-intl';
import { Skeleton, Menu, List, Dropdown, Row, Tooltip } from 'antd';
import { SettingOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';
import { TwitterService } from 'services/twitterApi';
import { useAuth } from 'hooks/auth';
import { Container, Title } from './styles';

interface Data {
  name: string;
  tweet_volume: number;
}

const WidgetHeader: React.FC<{
  setCountry: (value: string) => void;
  displayExportModal?: (value: boolean) => void;
}> = ({ setCountry, displayExportModal }) => {
  const intl = useIntl();
  const { user } = useAuth();

  const isUserLogged = useMemo(
    () => !!user.consumer_type && user.consumer_type !== 'unassigned_costumer',
    [user.consumer_type],
  );

  function getCountriesItems(
    i18n: IntlShape,
    countrySetter: (country: string) => void,
  ): JSX.Element {
    const items = [
      'world',
      'brazil',
      'portugal',
      'spain',
      'mexico',
    ].map(country => (
      <Menu.Item onClick={() => countrySetter(country)}>
        {i18n.formatMessage({ id: `trends.widget.modal.${country}` })}
      </Menu.Item>
    ));

    return <Menu>{items}</Menu>;
  }

  const countryMenu = useCallback(() => getCountriesItems(intl, setCountry), [
    intl,
    setCountry,
  ]);

  function getExportsIcon(): JSX.Element {
    if (!displayExportModal || !isUserLogged) return <span>&nbsp;</span>;

    return (
      <Tooltip title={intl.formatMessage({ id: 'trends.export.tooltip' })}>
        <CloudDownloadOutlined onClick={() => displayExportModal(true)} />
      </Tooltip>
    );
  }

  return (
    <Title>
      <strong>{intl.formatMessage({ id: 'trends.widget.x.title' })}</strong>
      <Row justify="end">
        {/*{getExportsIcon()}*/}
        <Dropdown overlay={countryMenu} placement="bottomLeft" arrow>
          <SettingOutlined />
        </Dropdown>
      </Row>
    </Title>
  );
};

const loadingArray = [{}, {}, {}, {}, {}] as Data[];

const Item: React.FC<Data> = ({ name, tweet_volume }: Data) => (
  <List.Item>
    <a
      href={`https://twitter.com/search?q=${name}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <strong>{name}</strong>
    </a>
    <span>{new Intl.NumberFormat('pt-BR').format(tweet_volume)}</span>
  </List.Item>
);

const TwitterWidget: React.FC<{
  displayExportModal: (value: boolean) => void;
}> = ({ displayExportModal }) => {
  const [data, setData] = useState<Data[]>([]);
  const [country, setCountry] = useState('world');
  const [loading, setLoading] = useState(false);

  async function getTrends(countryName: string): Promise<Array<Data>> {
    const response = await TwitterService.getTrendsByCoutry(countryName);

    const [{ trends }] = response.data.body;
    const filtered = trends.filter((trend: Data) => trend.tweet_volume > 0);

    return orderBy(filtered, 'tweet_volume', 'desc');
  }

  useEffect(() => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => getTrends(country))
      .then(trends => setData(trends))
      .then(() => setLoading(false));
  }, [country]);

  return (
    <Container
      title={
        <WidgetHeader
          setCountry={setCountry}
          displayExportModal={displayExportModal}
        />
      }
    >
      <List
        dataSource={loading ? loadingArray : data}
        pagination={{ pageSize: 5 }}
        renderItem={item => (
          <Skeleton loading={loading} active paragraph={false}>
            <Item name={item.name} tweet_volume={item.tweet_volume} />
          </Skeleton>
        )}
      />
    </Container>
  );
};

export default TwitterWidget;
