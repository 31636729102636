/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo } from 'react';
import { IPost } from 'hooks/search';
import numeral from 'numeral';
import {
  LikeOutlined,
  MessageOutlined,
  LinkOutlined,
  RiseOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { uuid } from 'uuidv4';
import {
  Container,
  Title,
  Content,
  Metrics,
  PostImage,
  PostVideo,
} from './style';

interface Props {
  data: IPost;
  seeFullPost?: () => void;
  index?: number;
  full?: boolean;
}

const Post: React.FC<Props> = ({ data, seeFullPost, index, full }) => {
  const intl = useIntl();
  const { author } = data;

  const dateFormatted = useMemo(() => {
    const formatted = data.date.match(/.{1,2}/g);
    if (formatted) {
      return `${formatted[4]}:${formatted[5]} ${formatted[3]}/${formatted[2]}/${
        formatted[0] + formatted[1]
      } `;
    }
    return '';
  }, [data.date]);

  const metrics = useMemo(() => {
    switch (data.service) {
      case 'facebook': {
        const { likes, reactions, comments_amounts, shares } = data;
        const sum =
          (likes || 0) +
          (reactions || 0) +
          (comments_amounts || 0) +
          (shares || 0);
        return [
          {
            icon: <RiseOutlined />,
            name: intl.formatMessage({ id: 'posts.interactions' }),
            value: numeral(sum).format('0.[0]a').toUpperCase(),
          },
          {
            icon: <LikeOutlined />,
            name: intl.formatMessage({ id: 'posts.likes' }),
            value: numeral(likes).format('0.[0]a').toUpperCase(),
          },
          {
            icon: <MessageOutlined />,
            name: intl.formatMessage({ id: 'posts.comments' }),
            value: numeral(comments_amounts).format('0.[0]a').toUpperCase(),
          },
          {
            icon: <LinkOutlined />,
            name: intl.formatMessage({ id: 'posts.shares' }),
            value: numeral(shares).format('0.[0]a').toUpperCase(),
          },
        ];
      }
      case 'instagram': {
        const { likes, reactions, comments_amounts, shares } = data;
        const sum =
          (likes || 0) +
          (reactions || 0) +
          (comments_amounts || 0) +
          (shares || 0);
        return [
          {
            icon: <RiseOutlined />,
            name: intl.formatMessage({ id: 'posts.interactions' }),
            value: numeral(sum).format('0.[0]a').toUpperCase(),
          },
          {
            icon: <LikeOutlined />,
            name: intl.formatMessage({ id: 'posts.likes' }),
            value: numeral(likes).format('0.[0]a').toUpperCase(),
          },
          {
            icon: <MessageOutlined />,
            name: intl.formatMessage({ id: 'posts.comments' }),
            value: numeral(comments_amounts).format('0.[0]a').toUpperCase(),
          },
        ];
      }
      case 'twitter': {
        return [
          {
            icon: <UserOutlined />,
            name: intl.formatMessage({ id: 'twitter.author' }),
            value: `@${data.author.screenname}`,
          },
          {
            icon: <UsergroupAddOutlined />,
            name: intl.formatMessage({ id: 'twitter.followers' }),
            value: numeral(data.author.followers)
              .format('0.[0]a')
              .toUpperCase(),
          },
          {
            icon: <MessageOutlined />,
            name: 'Posts',
            value: numeral(data.author.tweets).format('0.[0]a').toUpperCase(),
          },
        ];
      }
      default: {
        return [];
      }
    }
  }, [data, intl]);

  const color = useMemo(() => {
    switch (data.service) {
      case 'facebook':
        return '#1877f2';
      case 'instagram':
        return '#cd2e92';
      case 'twitter':
        return 'black';
      default: {
        return '#666';
      }
    }
  }, [data.service]);

  return (
    <Container color={color}>
      <Title>
        <a
          href={data.service !== 'twitter' ? data.link : data.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${index ? `${index}. ` : ''}${
            author.name ||
            (data.service === 'instagram'
              ? intl.formatMessage({ id: 'posts.hashtags' })
              : '')
          }`}
        </a>
      </Title>
      <Content
        full={full}
        href={data.service !== 'twitter' ? data.link : data.url}
        target="_blank"
      >
        <span id="date">{dateFormatted}</span>
        {data.picture_full_size && data?.picture_full_size?.match('video') ? (
          <PostVideo full={full}>
            <video controls>
              <source src={data.picture_full_size} />
            </video>
          </PostVideo>
        ) : (
          <PostImage
            full={full}
            url={
              data.service !== 'twitter'
                ? data.picture_full_size
                : data.author.profile_image_url?.replace('normal', '400x400')
            }
          />
        )}
        <p>{data.content}</p>
      </Content>
      {!full && (
        <Button
          type="link"
          onClick={seeFullPost}
          block
          style={{ margin: '0', border: 'none' }}
        >
          {intl.formatMessage({ id: 'posts.button.seemore' })}
        </Button>
      )}
      <Metrics>
        {metrics.map(metric => (
          <div key={uuid()}>
            <p>
              {metric.icon}
              {metric.name}
            </p>
            <span>{metric.value}</span>
          </div>
        ))}
      </Metrics>
    </Container>
  );
};

export default Post;
