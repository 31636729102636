import React, { useCallback } from 'react';
import Modal, { ModalProps } from 'antd/lib/modal';
import { useIntl } from 'react-intl';
import { useSearch, IPost } from 'hooks/search';
import apiElasticsearch from 'services/elasticsearchApi';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import numeral from 'numeral';
import { Form, Radio, Slider, Button } from 'antd';
import formatStringDate from 'utils/formatStringDate';

interface Props extends ModalProps {
  service: 'facebook' | 'instagram' | 'twitter';
}

const DownloadModal: React.FC<Props> = ({ service, ...rest }) => {
  const intl = useIntl();
  const { queryBuilder, searchedTerms } = useSearch();

  const handleSubmit = useCallback(
    async values => {
      const response = await apiElasticsearch.post<{ data: IPost[] }>(
        '/historical_posts.json',
        queryBuilder({
          service: [service],
          sort_by: 'top_posts_field',
          size: values.quantity,
          customParams: searchedTerms[0],
        }),
      );

      const parsed_data = response.data.data.map(post => [
        post.post_id || 'N/A',
        post.author.profile ||
          post.user_login ||
          post.author.screenname ||
          'N/A',
        post.url || 'N/A',
        formatStringDate(post.date) || 'N/A',
        numeral(post.likes).format('0.0a') || 'N/A',
        numeral(post.shares).format('0.0a') || 'N/A',
        numeral(post.comments_amounts).format('0.0a') || 'N/A',
        post.content || 'N/A',
        post.service || 'N/A',
        post.sentiment || 'N/A',
        post.engagement?.toFixed(3) || 'N/A',
      ]);
      const columns = [
        'ID',
        'AUTHOR',
        'URL',
        'DATE',
        'LIKES',
        'SHARES',
        'COMMENTS',
        'CONTENT',
        'SERVICE',
        'SENTIMENT',
        'ENGAGEMENT',
      ];
      const sheetArrays = [columns, ...parsed_data];

      const SheetName = `${service} Trending ${new Date()
        .toLocaleDateString()
        .split('/')
        .join(' ')}`;
      const sheet = XLSX.utils.aoa_to_sheet(sheetArrays);
      if (values.format === 'csv') {
        const csvSheet = XLSX.utils.sheet_to_csv(sheet);
        const blob = new Blob([csvSheet], { type: 'text/csv' });
        return saveAs(blob, `${SheetName.split(' ').join('_')}.csv`);
      }
      const workbook = XLSX.utils.book_new();
      workbook.Props = {
        Title: SheetName,
        Subject: 'Trendig Topics',
        Author: 'Buzzmonitor',
        CreatedDate: new Date(),
      };

      workbook.SheetNames.push(SheetName);
      workbook.Sheets[SheetName] = sheet;
      return XLSX.writeFile(
        workbook,
        `${SheetName.split(' ').join('_')}.xlsx`,
        {
          bookType: 'xlsx',
          type: 'binary',
        },
      );
    },
    [queryBuilder, searchedTerms, service],
  );

  return (
    <Modal
      title={intl.formatMessage({ id: 'posts.button.download' })}
      footer={<></>}
      {...rest}
    >
      <Form onFinish={handleSubmit}>
        <Form.Item
          label={intl.formatMessage({ id: 'downloadposts.subtitle' })}
          style={{ alignItems: 'center' }}
          name="format"
        >
          <Radio.Group>
            <Radio value="csv">CSV</Radio>
            <Radio value="xslx">XLSX</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'downloadposts.quantity' })}
          name="quantity"
        >
          <Slider min={1} max={500} />
        </Form.Item>
        <Button htmlType="submit" type="primary" block>
          {intl.formatMessage({ id: 'downloadposts.button' })}
        </Button>
      </Form>
    </Modal>
  );
};

export default DownloadModal;
