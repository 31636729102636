import React, { useEffect } from 'react';
import formatParamsToObj from 'utils/formatParamsToObj';
import { useSearch } from 'hooks/search';
import ResultsHeader from 'components/ResultsHeader';
import TermEvolution from 'components/ChartContainers/TermsEvolutionContainer';
import SharingByNetwork from 'components/ChartContainers/SharingByNetworkContainer';
import Sentiment from 'components/ChartContainers/SentimentContainer';
import Gender from 'components/ChartContainers/GenderContainer';
import TopPostsFacebook from 'components/TopPosts/Facebook';
import TopPostsInstagram from 'components/TopPosts/Instagram';
import TopPostsTwitter from 'components/TopPosts/Twitter';
import { Row, Col } from 'antd';
import InfluencersWidget from 'components/InfluencersWidget';
import { formatInfluencersQuery } from 'utils/formatQuery';
import { Container } from './styles';

const Results: React.FC = () => {
  const { setParams, setSearchedTerms, setComparisonTerms } = useSearch();

  /*
    Gets page URL and initializes params, searchedTerms and compareTerms
  */
  useEffect(() => {
    const urlParams = window.location.search;
    const paramsObj = formatParamsToObj(urlParams);

    // Formats obligatory, banned and optional terms in one query string
    const newObligatoryQuery = formatInfluencersQuery(paramsObj).replace(
      /""/g,
      '"',
    );

    // Build a new params obj with the query string in the obligatory field
    const newParamsObj = {
      ...paramsObj,
      banned: '',
      optional: '',
      obligatory: newObligatoryQuery,
    };

    // Initializes params, searchedTerms and compareTerms
    setParams(newParamsObj);
    const comparisonTerms = newParamsObj.compare.map(term => ({
      ...newParamsObj,
      obligatory: term,
    }));
    setSearchedTerms([newParamsObj, ...comparisonTerms]);
    setComparisonTerms([...comparisonTerms]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ResultsHeader />
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <TermEvolution />
        </Col>
        <Col span={24}>
          <SharingByNetwork />
        </Col>
        <Col xs={24} lg={12}>
          <Gender />
        </Col>
        <Col xs={24} lg={12}>
          <Sentiment />
        </Col>
        <Col span={24}>
          <TopPostsFacebook />
        </Col>
        <Col span={24}>
          <TopPostsInstagram />
        </Col>
        <Col span={24}>
          <TopPostsTwitter />
        </Col>
        <Col span={24}>
          <InfluencersWidget />
        </Col>
      </Row>
    </Container>
  );
};

export default Results;
