import styled from 'styled-components';
import BrokenLink from 'assets/images/broken-link.png';

export const Container = styled.div<{ color: string }>`
  color: ${props => props.color};
`;

export const Content = styled.a<{ full?: boolean }>`
  display: grid;
  grid-template-columns: repeat(1fr);
  text-decoration: none;
  color: #494949;
  span#date {
    color: #999;
    margin-bottom: 5px;
    font-size: 0.73rem;
  }
  p {
    margin-top: 8px;
    height: ${props => (props.full ? '100%' : '3.6rem')};
    overflow: hidden;
    line-break: loose;
  }
  :hover {
    color: inherit;
    p {
      filter: brightness(70%);
    }
  }
`;

export const PostImage = styled.div<{ url?: string; full?: boolean }>`
  background-image: url(${props => props.url || BrokenLink}), url(${BrokenLink});
  background-position: center center;
  background-size: cover;

  width: 100%;
  height: ${props => (props.full ? '352px' : '251.5px')};
  border-radius: 4px;
`;

export const PostVideo = styled.div<{ full?: boolean }>`
  height: ${props => (props.full ? '352px' : '251.5px')};
  width: auto;
  object-fit: cover;
  overflow: hidden;
  display: grid;
  place-items: center;
  overflow: hidden;
  video {
    object-fit: cover;
    border-radius: 4px;
    height: 100%;
    width: 100%;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  a {
    line-height: 20px;
    overflow: hidden;
    font-weight: bold;
    color: inherit;
  }
`;

export const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    border-top: 1px solid #e8e8e8;
    svg {
      margin-right: 8px;
    }
    p {
      margin: 0;
      color: #494949;
    }
  }
`;
