import styled from 'styled-components';
import { Card, Button, Input } from 'antd';

export const ContainerTerm = styled(Card.Grid)`
  flex-grow: 1;
  min-width: 150px;
  width: min-content;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  padding: 12px;
  padding-right: 25px;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const TermCardButton = styled(Button).attrs({
  shape: 'circle',
  type: 'link',
})`
  height: fit-content;
  width: fit-content;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: fit-content;
  width: fit-content;
`;

export const Terms = styled.span`
  font-size: 1.2rem;
  white-space: nowrap;
`;

export const InputANT = styled(Input)`
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
