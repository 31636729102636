import React, { useMemo, memo } from 'react';
import useCldrTranslator from 'hooks/cldrTranslator';

import { useIntl } from 'react-intl';
import { Tag, Popover } from 'antd';
import { uuid } from 'uuidv4';
import SocialIcon from 'components/SocialIcon';
import formatNumber from 'utils/formatNumber';
import {
  Container,
  Title,
  Content,
  PostImage,
  SectionDivider,
  Label,
  TagContainer,
  TagGroup,
  Social,
  SocialFollowers,
  SocialCount,
  PopOverText,
} from './styles';

export interface SocialType {
  id: string;
  followers: number;
  social_network: string;
}

export interface InfluencerType {
  id?: string;
  name: string;
  photo: string;
  social: SocialType[];
  interests: string[];
  languages?: string[];
}

interface PostProps {
  data: InfluencerType;
  index?: number;
}

const Post: React.FC<PostProps> = ({ data, index }) => {
  const intl = useIntl();
  const translateLanguage = useCldrTranslator(
    intl.formatMessage({ id: 'general.locale' }),
  );
  const { name, photo, social, interests, languages } = data;

  const formattedLanguages = useMemo(() => {
    return languages?.flatMap((lang: string) => {
      return translateLanguage(lang) || [];
    });
  }, [languages, translateLanguage]);

  return (
    <Container>
      <a
        href={`https://influencers.app.buzzmonitor.com.br/influencers/${data.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Title>{`${index ? `${index}. ` : ''}${name}`}</Title>
        <Content cover={photo && <PostImage url={photo} />}>
          {formattedLanguages && formattedLanguages.length > 0 && (
            <TagSection
              label={intl.formatMessage({
                id: 'influencers.widget.post.languages',
              })}
              tags={formattedLanguages}
            />
          )}
          {interests && interests.length > 0 && (
            <>
              <SectionDivider />
              <TagSection
                label={intl.formatMessage({
                  id: 'influencers.widget.post.interests',
                })}
                tags={interests}
              />
            </>
          )}
          {social && social.length > 0 && (
            <>
              <SectionDivider />
              <SocialPreview socials={social} />
            </>
          )}
        </Content>
      </a>
    </Container>
  );
};

interface TagSectionProps {
  label: string;
  tags: string[];
}

const TagSection: React.FC<TagSectionProps> = ({ label, tags }) => {
  return (
    <TagContainer>
      <Label>{label}</Label>
      <TagGroup>
        {tags.map(item => (
          <Tag key={item}>{item}</Tag>
        ))}
      </TagGroup>
    </TagContainer>
  );
};

interface SocialPreviewProps {
  socials: SocialType[];
}

const SocialPreview: React.FC<SocialPreviewProps> = ({ socials }) => {
  const intl = useIntl();
  const socialsFormatted = useMemo(
    () => [
      socials.slice(0, 4),
      socials.length > 4 ? socials.slice(4, socials.length) : [],
    ],
    [socials],
  );

  return (
    <Social>
      {socialsFormatted[0].map(social => {
        const { social_network, followers } = social;
        const id = uuid();
        const formattedCount = formatNumber(followers);
        return (
          <SocialFollowers key={id}>
            <SocialIcon social_network={social_network} />
            <SocialCount>{formattedCount}</SocialCount>
          </SocialFollowers>
        );
      })}

      {socialsFormatted[1].length > 0 && (
        <Popover
          content={`+ ${socialsFormatted[1].length} ${intl.formatMessage({
            id: 'influencers.widget.post.social-profiles',
          })}`}
        >
          <PopOverText>{`+ ${socialsFormatted[1].length}`}</PopOverText>
        </Popover>
      )}
    </Social>
  );
};

const comparisonFn = (prevProps: PostProps, newProps: PostProps): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(newProps);
};

export default memo(Post, comparisonFn);
