import React, { useEffect } from 'react';
import { Modal, Form, DatePicker, Select, Tag } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { useIntl } from 'react-intl';
import moment from 'moment';
import type { RangePickerProps } from 'antd/es/date-picker';
import { SelectProps } from 'antd/lib/select';
import { validateEmail } from 'utils/emailUtils';
import { TwitterService } from 'services/twitterApi';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

interface DatetimeRange {
  start: moment.Moment;
  end: moment.Moment;
}

const EmailSelector: React.FC<{
  onChange: (emails: Array<string>) => void;
  validInput: (valid: boolean) => void;
}> = ({ onChange, validInput }) => {
  const [emails, setEmails] = React.useState<Array<string>>([]);

  function renderOption({ value }: SelectProps<unknown>): JSX.Element {
    const valueAsString = value as string;
    const isValidEmail = validateEmail(valueAsString);

    function removeEmail(): void {
      setEmails(emails.filter(email => email !== valueAsString));
    }

    return (
      <Tag
        closable
        color={isValidEmail ? 'default' : 'red'}
        style={{ marginRight: 3 }}
        onClose={removeEmail}
      >
        {valueAsString}
      </Tag>
    );
  }

  useEffect(() => {
    const hasEmails = emails.length > 0;
    const emailsAreValid = emails.every(validateEmail);

    validInput(hasEmails && emailsAreValid);
    onChange(emails);
  }, [emails, validInput, onChange]);

  return (
    <Select
      mode="tags"
      value={emails}
      style={{ width: '100%' }}
      onChange={(raw: Array<string>) => setEmails(raw)}
      tokenSeparators={[',', ' ']}
      open={false}
      tagRender={renderOption}
    />
  );
};

const DatetimeRangePicker: React.FC<{
  onChange: (range: DatetimeRange) => void;
  validInput: (valid: boolean) => void;
}> = ({ onChange, validInput }) => {
  const intl = useIntl();
  const [range, setRange] = React.useState<DatetimeRange>({
    start: moment().subtract(1, 'days'),
    end: moment(),
  });

  useEffect(() => onChange(range), [onChange, range]);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > moment();
  };

  function confirm(period: RangePickerProps['value']): void {
    const [start, end] = period ?? [];
    if (!start || !end) {
      validInput(false);
      return;
    }
    validInput(true);

    setRange({
      start: start as moment.Moment,
      end: end as moment.Moment,
    });
  }

  const timeFormat = intl.formatMessage({ id: 'general.format.time' });
  const dateFormat = intl.formatMessage({ id: 'general.format.date' });

  return (
    <DatePicker.RangePicker
      style={{ width: '75%' }}
      allowClear={false}
      showTime={{ format: timeFormat }}
      format={`${dateFormat} ${timeFormat}`}
      disabledDate={disabledDate}
      defaultValue={[range.start, range.end]}
      placeholder={[
        intl.formatMessage({ id: 'trends.export.modal.period.since' }),
        intl.formatMessage({ id: 'trends.export.modal.period.until' }),
      ]}
      onOk={confirm}
    />
  );
};

interface ExportTrendsModalProps extends ModalProps {
  dispose: () => void;
}

export const ExportTrendsModal: React.FC<ExportTrendsModalProps> = ({
  dispose,
  ...props
}: ExportTrendsModalProps) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const [validEmail, setValidEmail] = React.useState(false);
  const [validDateRange, setValidDateRange] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [emails, setEmails] = React.useState<Array<string>>([]);
  const [period, setPeriod] = React.useState<DatetimeRange>();

  function showSuccess(): void {
    dispose();
    Modal.success({
      title: intl.formatMessage({ id: 'trends.export.modal.success.title' }),
      icon: <CheckCircleOutlined />,
      content: intl.formatMessage({
        id: 'trends.export.modal.success.content',
      }),
      okText: intl.formatMessage({ id: 'trends.export.modal.response.ok' }),
    });
  }

  function showFailure(): void {
    dispose();
    Modal.error({
      title: intl.formatMessage({ id: 'trends.export.modal.failure.title' }),
      icon: <CloseCircleOutlined />,
      type: 'error',
      content: intl.formatMessage({
        id: 'trends.export.modal.failure.content',
      }),
      okText: intl.formatMessage({ id: 'trends.export.modal.response.ok' }),
    });
  }

  function formatDatetime(datetime?: moment.Moment): string {
    return datetime?.format('YYYY-MM-DD HH:mm ZZ') ?? '';
  }

  function handleOk(): void {
    setLoading(true);
    TwitterService.exportTrends(emails, {
      start: formatDatetime(period?.start),
      end: formatDatetime(period?.end),
    })
      .then(() => showSuccess())
      .catch(() => showFailure())
      .finally(() => setLoading(false));
  }

  return (
    <Modal
      {...props}
      title={intl.formatMessage({ id: 'trends.export.modal.title' })}
      cancelText={intl.formatMessage({ id: 'trends.export.modal.cancel' })}
      okText={intl.formatMessage({ id: 'trends.export.modal.ok' })}
      okButtonProps={{
        disabled: !validEmail && !validDateRange,
        htmlType: 'submit',
      }}
      onOk={handleOk}
      onCancel={dispose}
      confirmLoading={loading}
      destroyOnClose
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={intl.formatMessage({ id: 'trends.export.modal.period' })}
        >
          <DatetimeRangePicker
            onChange={setPeriod}
            validInput={setValidDateRange}
          />
        </Form.Item>
        <Form.Item
          required
          label={intl.formatMessage({ id: 'trends.export.modal.email' })}
        >
          <EmailSelector onChange={setEmails} validInput={setValidEmail} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExportTrendsModal;
