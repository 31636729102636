import React, { useMemo, useCallback, memo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'react-intl';
import { Metric } from 'hooks/search';
import { Empty, Col, Row } from 'antd';
import { uuid } from 'uuidv4';

interface SharingByNetworkChartProps {
  data: Metric[];
  title?: string;
}

const SharingByNetworkChart: React.FC<SharingByNetworkChartProps> = ({
  data,
  title = '',
}) => {
  const intl = useIntl();

  const colors = useCallback((color: string): string[] => {
    switch (color) {
      case 'facebook':
        return ['#C6D1E8', '#1877f2'];
      case 'instagram':
        return ['#F0C0DE', '#CD2E92'];
      case 'x':
        return ['#CCCCCC', '#000000'];
      default: {
        return ['#e8e8e8', '#666666'];
      }
    }
  }, []);

  const totalValue = useMemo(
    () => (data ? data.reduce((prev, current) => prev + current.value, 0) : 0),
    [data],
  );

  const formattedData: Array<{
    name: 'facebook' | 'instagram' | 'x';
    value: number;
  }> = useMemo(
    () =>
      data
        ? data
            .map(
              service =>
                ({
                  name: (service.label === 'twitter') ? "x" : service.label,
                  value: service.value,
                } as {
                  name: 'facebook' | 'instagram' | 'x';
                  value: number;
                }),
            )
            .sort((a, b) => {
              return a.name.localeCompare(b.name);
            })
        : [],
    [data],
  );

  return (
    <Row gutter={[0, 24]} justify="center">
      <Col span={24} key={uuid()}>
        {formattedData.length > 0 ? (
          <ReactEcharts
            ref={e => {
              return e?.getEchartsInstance().setOption({
                baseOption: {
                  tooltip: {
                    trigger: 'item',
                    formatter: '{d}%',
                  },
                  title: {
                    textStyle: {
                      fontSize: 16,
                    },
                  },
                  textStyle: {
                    fontFamily: 'Lato',
                  },
                  toolbox: {
                    show: true,
                    right: 0,
                    feature: {
                      saveAsImage: {
                        show: true,
                        title: intl.formatMessage({ id: 'toolbox.save' }),
                        name: 'shares_by_network',
                        excludeComponents: ['toolbox'],
                      },
                    },
                  },
                  series: formattedData.map((item, index) => ({
                    type: 'pie',

                    avoidLabelOverlap: true,
                    seriesLayoutBy: 'column',
                    name: item.name,
                    data: [
                      {
                        value: totalValue - item.value,
                        itemStyle: {
                          color: colors(item.name)[0],
                        },
                        label: {
                          show: true,
                          position: 'center',
                          rich: {
                            total: {
                              padding: [-275, 0, 0, 0],
                              height: 300,
                              fontSize: 17,
                              lineHeight: 17,
                              color: colors(item.name)[1],
                            },
                          },
                          formatter: () => {
                            return `{total|${new Intl.NumberFormat(
                              'pt-BR',
                            ).format(item.value)} ${intl.formatMessage({
                              id: 'results.representativeness.collected',
                            })}}`;
                          },
                        },
                      },
                      {
                        ...item,
                        itemStyle: {
                          color: colors(item.name)[1],
                        },
                        verticalAlign: 'bottom',
                        label: {
                          show: true,
                          position: 'center',
                          fontSize: 18,
                          lineHeight: 24,
                          fontWeight: 'bold',
                          formatter: (dataFormat: {
                            name: string;
                            percent: number;
                          }) => {
                            return `${
                              dataFormat.name.charAt(0).toUpperCase() +
                              dataFormat.name.slice(1)
                            }\n${dataFormat.percent} %`;
                          },
                        },
                      },
                    ],
                  })),
                },
                media: [
                  {
                    query: { maxWidth: 1500 },
                    option: {
                      series: [
                        {
                          type: 'pie',
                          radius: ['45%', '70%'],
                          center: [`${(1 / 6) * 100}%`, '45%'],
                        },
                        {
                          type: 'pie',
                          radius: ['45%', '70%'],
                          center: [`${(3 / 6) * 100}%`, '45%'],
                        },
                        {
                          type: 'pie',
                          radius: ['45%', '70%'],
                          center: [`${(5 / 6) * 100}%`, '45%'],
                        },
                      ],
                    },
                  },
                  {
                    query: { maxWidth: 880 },
                    option: {
                      tooltip: {
                        show: false,
                      },
                      series: [
                        {
                          type: 'pie',
                          center: [`25%`, '25%'],
                          radius: ['20%', '35%'],
                        },
                        {
                          type: 'pie',
                          center: [`75%`, '25%'],
                          radius: ['20%', '35%'],
                        },
                        {
                          type: 'pie',
                          center: [`50%`, '75%'],
                          radius: ['20%', '35%'],
                        },
                      ],
                    },
                  },
                  {
                    query: { maxWidth: 720 },
                    option: {
                      series: [
                        {
                          type: 'pie',
                          center: ['50%', `${(1 / 6) * 100}%`],
                          radius: ['80px', '120px'],
                        },
                        {
                          type: 'pie',
                          center: ['50%', `${(3 / 6) * 100}%`],
                          radius: ['80px', '120px'],
                        },
                        {
                          type: 'pie',
                          center: ['50%', `${(5 / 6) * 100}%`],
                          radius: ['80px', '120px'],
                        },
                      ],
                    },
                  },
                  {
                    query: { maxWidth: 400 },
                    option: {
                      series: [
                        {
                          type: 'pie',
                          center: ['50%', `${(1 / 6) * 100}%`],
                          radius: ['38%', '58%'],
                        },
                        {
                          type: 'pie',
                          center: ['50%', `${(3 / 6) * 100}%`],
                          radius: ['38%', '58%'],
                        },
                        {
                          type: 'pie',
                          center: ['50%', `${(5 / 6) * 100}%`],
                          radius: ['38%', '58%'],
                        },
                      ],
                    },
                  },
                ],
              });
            }}
            option={{
              title: {
                show: true,
                text: title,
              },
            }}
          />
        ) : (
          <Empty
            description={intl.formatMessage({ id: 'posts.no-data-found' })}
          />
        )}
      </Col>
    </Row>
  );
};

const comparisonFn = (
  prevProps: SharingByNetworkChartProps,
  newProps: SharingByNetworkChartProps,
): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(newProps);
};

export default memo(SharingByNetworkChart, comparisonFn);
