import React, { useState, useCallback, useEffect, memo } from 'react';
import { useIntl } from 'react-intl';
import { IPost } from 'hooks/search';
import Post from 'components/Post';
import { Button, Row, Col, Empty, Modal } from 'antd';
import { useAuth } from 'hooks/auth';
import ActiveCampaign from 'components/Modals/ActiveCampaign';

import { I18nProvider } from 'hooks/i18n';
import { uuid } from 'uuidv4';
import { Container } from './styles';

interface PostContainerProps {
  data: IPost[];
  title?: string;
  socialIcon?: React.ReactNode;
}

const PostContainer: React.FC<PostContainerProps> = ({
  data,
  title = '',
  socialIcon = undefined,
}) => {
  const intl = useIntl();
  const { user } = useAuth();

  const [activeCampaign, setActiveCampaign] = useState(false);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (data) setPosts([...data].slice(0, 4));
    setPage(1);
  }, [data]);

  const handleSeeMore = useCallback(() => {
    if (!user.consumer_type || user.consumer_type === 'unassigned_costumer') {
      return setActiveCampaign(true);
    }
    if (data)
      setPosts([...posts, ...[...data].slice(page * 4, (page + 1) * 4)]);
    return setPage(page + 1);
  }, [data, page, posts, user.consumer_type]);

  const handleSeeFullPost = useCallback(
    (post: IPost) =>
      user.consumer_type && user.consumer_type !== 'unassigned_costumer'
        ? Modal.info({
            icon: socialIcon,
            content: (
              <I18nProvider>
                <Post data={post} full />
              </I18nProvider>
            ),
          })
        : setActiveCampaign(true),
    [socialIcon, user.consumer_type],
  );

  return (
    <Container>
      <h5>{title}</h5>
      {data && data.length > 0 ? (
        <>
          <Row gutter={[16, 8]}>
            {posts.map((post, index) => (
              <Col xs={24} sm={12} lg={6} key={uuid()}>
                <Post
                  data={post}
                  index={index + 1}
                  seeFullPost={() => handleSeeFullPost(post)}
                />
              </Col>
            ))}
          </Row>
          {posts.length !== data.length && (
            <Row justify="center">
              <Button shape="round" onClick={handleSeeMore}>
                {intl.formatMessage({ id: 'posts.button.seemore' })}
              </Button>
            </Row>
          )}
        </>
      ) : (
        <Empty
          description={intl.formatMessage({ id: 'posts.no-data-found' })}
        />
      )}
      <ActiveCampaign
        visible={activeCampaign}
        onCancel={() => setActiveCampaign(false)}
      />
    </Container>
  );
};

const comparisonFn = (
  prevProps: PostContainerProps,
  newProps: PostContainerProps,
): boolean => {
  return JSON.stringify(prevProps.title) === JSON.stringify(newProps.title);
};

export default memo(PostContainer, comparisonFn);
