import React, { useState, useEffect } from 'react';
import TamiLogo from 'assets/images/tami.png';
import { useSearch } from 'hooks/search';
import ActiveCampaign from 'components/Modals/ActiveCampaign';
import { difference, map } from 'lodash';
import { formatInfluencersQuery } from 'utils/formatQuery';
import SectionTitle from 'components/SectionTitle';
import { Container, Header } from './styles';
import PostContainer from './PostContainer';

export interface ParamDataType {
  query: string;
}

const WidgetHeader: React.FC = () => {
  return (
    <Header>
      <img src={TamiLogo} alt="Buzzmonitor Influencers" />
      <SectionTitle>Influencers</SectionTitle>
    </Header>
  );
};

const InfluencersWidget: React.FC = () => {
  const [paramsDatas, setParamsDatas] = useState<Map<string, ParamDataType>>(
    new Map(),
  );
  const [activeCampaign, setActiveCampaign] = useState(false);
  const { params, searchedTerms, comparisonTerms } = useSearch();

  /*
    Check if some term was removed from searchedTerms and remove its data from
    paramsDatas, removing the chart of term.
  */
  useEffect(() => {
    if (searchedTerms.length < paramsDatas.size) {
      const keyToRemove = difference(
        Array.from(paramsDatas.keys()),
        map(searchedTerms, 'obligatory'),
      );
      setParamsDatas(prevDatas => {
        prevDatas.delete(keyToRemove?.[0]);
        return new Map(prevDatas);
      });
    }
  }, [paramsDatas, searchedTerms]);

  useEffect(() => {
    setParamsDatas(
      prevDatas =>
        new Map(
          prevDatas.set(params.obligatory, {
            query: formatInfluencersQuery(params),
          }),
        ),
    );
  }, [params]);

  /*
    This effect is used to search for the comparison terms when the page is
    loaded for the first time.
  */
  useEffect(() => {
    if (comparisonTerms.length > 0) {
      comparisonTerms.forEach(term => {
        setParamsDatas(
          prevDatas =>
            new Map(
              prevDatas.set(term.obligatory, {
                query: formatInfluencersQuery(term),
              }),
            ),
        );
      });
    }
  }, [comparisonTerms]);

  return (
    <Container title={<WidgetHeader />}>
      {paramsDatas &&
        paramsDatas.size >= 1 &&
        Array.from(paramsDatas).map(([key, value]) => (
          <PostContainer
            title={paramsDatas.size > 1 ? key : ''}
            searchFor={value.query}
            languages={params.languages}
            key={key}
          />
        ))}
      <ActiveCampaign
        visible={activeCampaign}
        onCancel={() => setActiveCampaign(false)}
      />
    </Container>
  );
};

export default InfluencersWidget;
