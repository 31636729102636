import React, { useEffect, useState } from 'react';
import SearchForm from 'components/SearchForm';
import { useAuth } from 'hooks/auth';
import TwitterWidget from 'components/Widgets/Twitter';
import GoogleWidget from 'components/Widgets/Google';
import { ExportTrendsModal } from 'components/Widgets/Twitter/ExportTrendsModal';
import { Container } from './styles';

const Home: React.FC = () => {
  const { authorizeUser } = useAuth();
  const [displayExportTrends, setDisplayExportTrends] = useState(false);

  useEffect(() => {
    authorizeUser();
  }, [authorizeUser]);

  return (
    <Container>
      <main>
        <SearchForm />
        <GoogleWidget />
        <TwitterWidget displayExportModal={setDisplayExportTrends} />
        <ExportTrendsModal
          visible={displayExportTrends}
          dispose={() => setDisplayExportTrends(false)}
        />
      </main>
    </Container>
  );
};

export default Home;
