import styled from 'styled-components';
import { Card } from 'antd';

export const Container = styled(Card)`
  border-top: 4px solid #dd5b70 !important;
  grid-area: widgetGT;
  width: 100%;
  div.ant-card-body {
    padding: 0;
    li.ant-list-item {
      padding: 8px 24px;
      a {
        color: inherit;
      }
    }
    div.ant-list-pagination {
      margin: 8px 0;
      ul.ant-pagination {
        display: flex;
        justify-content: center;
        padding-bottom: 4px;
      }
    }
    div.ant-skeleton-content {
      padding: 0px 24px;
      h3 {
        height: 23px;
        margin: 8px 0;
      }
    }
  }
`;
export const Title = styled.header`
  color: #dd5b70;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  strong {
    font-size: 1rem;
  }
  svg {
    height: 1rem;
    width: auto;
    cursor: pointer;
  }
`;
