import { Form as FormANT, Card as CardANT } from 'antd';
import styled from 'styled-components';

export const Form = styled(FormANT).attrs({
  layout: 'vertical',
})`
  padding: 16px 16px 0px;

  p {
    height: 1rem;
  }
  div.ant-col {
    div.ant-form-item {
      margin-bottom: 0px;
      & + div.ant-form-item {
        margin-top: 16px;
      }
    }
  }
  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 370px) {
    form.ant-form {
      padding: 8px;
    }
  }
`;

export const Card = styled(CardANT)`
  grid-area: search;
  width: 100%;
  @media (max-width: 370px) {
    div.ant-card-body {
      padding: 0;
    }
  }
`;
