import React from 'react';

import styled from 'styled-components';

const Title = styled.h4`
  font-size: 20px;
`;

const SectionTitle: React.FC = ({ children }) => {
  return <Title>{children}</Title>;
};

export default SectionTitle;
