import styled from 'styled-components';
import { Card } from 'antd';

export const Container = styled(Card)`
  grid-area: topInfluencers;
  border-top: 4px solid #fbb958 !important;
  div.ant-card-body {
    div.ant-skeleton-header {
      padding: 0;
    }
    button {
      margin-top: 16px;
      color: #fbb958;
      border-color: #fbb958;
    }
  }
  img.x-logo {
    width: 14px;
    margin: 5px 0;
    opacity: 0.3;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  h4 {
    margin-right: auto;
  }
  img {
    height: 32px;
    width: 32px;
    margin-right: 16px;
  }
  svg {
    color: #fbb958;
    height: 32px;
    width: 32px;
  }
`;
