import React, { useMemo } from 'react';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import { useSearch, Language } from 'hooks/search';

interface Option {
  value: string;
  name: string;
}
interface Props {
  label: string;
}

const MultipleSelect: React.FC<Props> = ({ label }) => {
  const intl = useIntl();
  const { params, setParams } = useSearch();

  const options: Array<Option> = useMemo(() => {
    return [
      {
        value: 'pt',
        name: intl.formatMessage({ id: 'searchform.language.pt' }),
      },
      {
        value: 'en',
        name: intl.formatMessage({ id: 'searchform.language.en' }),
      },
      {
        value: 'es',
        name: intl.formatMessage({ id: 'searchform.language.es' }),
      },
    ];
  }, [intl]);

  return (
    <Form.Item label={label}>
      <Select
        mode="multiple"
        value={params.languages}
        onChange={(e: Language[]) => setParams({ ...params, languages: e })}
      >
        {options.map(({ value, name }) => (
          <Select.Option value={value} key={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default MultipleSelect;
