import React, { useMemo, useCallback, memo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'react-intl';
import { Metric } from 'hooks/search';
import { Empty } from 'antd';

interface SentimentChartProps {
  data: Metric[];
  title?: string;
  width?: string;
  height?: string;
}

const SentimentChart: React.FC<SentimentChartProps> = ({
  data,
  title = '',
  width = '100%',
  height = '300px',
}) => {
  const intl = useIntl();

  const colors = useCallback((key: string) => {
    switch (key) {
      case 'positive':
        return '#73ca72';
      case 'negative':
        return '#de413a';
      case 'neutral':
        return '#e5ed33';
      default:
        return '#666';
    }
  }, []);

  const formattedData = useMemo(
    () =>
      data && data.length > 0
        ? data.map(sentiment => {
            return {
              name: intl.formatMessage({
                id: `results.sentiment.${sentiment.label.toLowerCase()}`,
              }),
              value: sentiment.value,
              itemStyle: { color: colors(sentiment.label) },
            };
          })
        : [],
    [data, intl, colors],
  );
  return (
    <>
      {formattedData.length > 0 &&
      (formattedData[0]?.value !== 0 ||
        formattedData[1]?.value !== 0 ||
        formattedData[2]?.value !== 0) ? (
        <ReactEcharts
          style={{ height, width }}
          option={{
            title: {
              show: true,
              text: title,
              textStyle: {
                fontSize: 16,
              },
            },
            textStyle: {
              fontFamily: 'Lato',
            },
            tooltip: {
              trigger: 'item',
              formatter: '{b}: {c} ({d}%)',
            },
            legend: {
              type: 'plain',
              bottom: '0%',
              itemWidth: 15,
            },
            toolbox: {
              show: true,
              right: 0,
              top: 20,
              feature: {
                saveAsImage: {
                  show: true,
                  title: intl.formatMessage({ id: 'toolbox.save' }),
                  name: 'sentiments_buzzmonitor_trends',
                  excludeComponents: ['toolbox'],
                },
              },
            },
            series: [
              {
                type: 'pie',
                radius: ['0%', '75%'],
                avoidLabelOverlap: false,
                label: {
                  show: true,
                  fontWeight: 'bold',
                  position: 'inside',
                  formatter: '{d} %',
                },
                labelLine: {
                  show: false,
                },
                data: formattedData,
              },
            ],
          }}
        />
      ) : (
        <Empty
          description={intl.formatMessage({ id: 'posts.no-data-found' })}
        />
      )}
    </>
  );
};

const comparisonFn = (
  prevProps: SentimentChartProps,
  newProps: SentimentChartProps,
): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(newProps);
};

export default memo(SentimentChart, comparisonFn);
