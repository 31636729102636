import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto 0;
  h1 {
    font-weight: regular;
  }
  > main {
    margin-top: 88px;
    margin-bottom: 32px;
    width: 100%;
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 40px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'search search'
      'widgetGT widgetTT';
  }
  @media (max-width: 1080px) {
    padding: 0 24px;
    > main {
      margin-bottom: 32px;
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:
        'search '
        'widgetGT'
        'widgetTT';
    }
  }
  @media (max-width: 370px) {
    padding: 0 8px;
    > main {
      margin-bottom: 32px;
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:
        'search '
        'widgetGT'
        'widgetTT';
    }
  }
`;
