import React from 'react';
import XLogo from 'assets/images/lBlack.png';
import {
  FacebookFilled,
  InstagramFilled,
  YoutubeFilled,
} from '@ant-design/icons';

interface SocialIconProps {
  social_network: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ social_network }) => {
  if (social_network === 'facebook') return <FacebookFilled />;
  if (social_network === 'twitter') return <img className="x-logo" src={XLogo} alt="X" />;
  if (social_network === 'youtube') return <YoutubeFilled />;
  return <InstagramFilled />;
};

export default SocialIcon;
