import React, { useState, useCallback } from 'react';
import { Form, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { Params } from 'hooks/search';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import {
  ContainerTerm,
  Terms,
  TermCardButton,
  InputANT,
  ButtonsContainer,
} from './styles';

interface TermCardProps {
  term: Params;
  remove: (term: string) => void;
  edit: (oldTerm: string, newTerm: string) => void;
}

const TermCard: React.FC<TermCardProps> = ({ term, remove, edit }) => {
  const intl = useIntl();
  const [isClicked, setIsClicked] = useState(false);
  const [currentTerm, setCurrentTerm] = useState(term);

  const onSubmit = useCallback(
    (values): void => {
      edit(currentTerm.obligatory, values.termName);
      setIsClicked(false);
      setCurrentTerm(prevCurrentTerm => ({
        ...prevCurrentTerm,
        obligatory: values.termName,
        optional: '',
        banned: '',
      }));
    },
    [currentTerm, edit],
  );

  return (
    <ContainerTerm hoverable={false}>
      {isClicked ? (
        <Form onFinish={onSubmit}>
          <Form.Item name="termName" initialValue={currentTerm.obligatory}>
            <InputANT
              placeholder={intl.formatMessage({
                id: 'results.comparison.input-placeholder',
              })}
            />
          </Form.Item>
        </Form>
      ) : (
        <>
          <Tooltip title={currentTerm.obligatory}>
            <Terms>{currentTerm.obligatory}</Terms>
          </Tooltip>
          <ButtonsContainer>
            <TermCardButton
              icon={<CloseCircleOutlined />}
              onClick={() => remove(currentTerm.obligatory)}
            />
            <TermCardButton
              icon={<EditOutlined />}
              onClick={() => setIsClicked(!isClicked)}
            />
          </ButtonsContainer>
        </>
      )}
    </ContainerTerm>
  );
};

export default TermCard;
