import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SettingOutlined } from '@ant-design/icons';
import { List, Skeleton, Menu, Dropdown } from 'antd';
import Axios from 'axios';
import formatXMLtoJSON from 'utils/formatXMLtoJSON.js';
import { orderBy } from 'lodash';
import { Container, Title } from './styles';

const googleApiUrl =
  'https://cors-anywhere.herokuapp.com/https://trends.google.com/trends/trendingsearches/daily';

interface Data {
  title: string;
  count: number;
}

const WidgetHeader: React.FC<{ setCountry: (value: string) => void }> = ({
  setCountry,
}) => {
  const intl = useIntl();

  const countryMenu = useCallback(
    () => (
      <Menu>
        <Menu.Item onClick={() => setCountry('BR')}>
          {intl.formatMessage({ id: 'trends.widget.modal.brazil' })}
        </Menu.Item>
        <Menu.Item onClick={() => setCountry('PT')}>
          {intl.formatMessage({ id: 'trends.widget.modal.portugal' })}
        </Menu.Item>
        <Menu.Item onClick={() => setCountry('US')}>
          {intl.formatMessage({ id: 'trends.widget.modal.usa' })}
        </Menu.Item>
        <Menu.Item onClick={() => setCountry('CO')}>
          {intl.formatMessage({ id: 'trends.widget.modal.colombia' })}
        </Menu.Item>
        <Menu.Item onClick={() => setCountry('MX')}>
          {intl.formatMessage({ id: 'trends.widget.modal.mexico' })}
        </Menu.Item>
      </Menu>
    ),
    [intl, setCountry],
  );

  return (
    <Title>
      <strong>
        {intl.formatMessage({ id: 'trends.widget.google.title' })}
      </strong>
      <Dropdown overlay={countryMenu} placement="bottomLeft" arrow>
        <SettingOutlined />
      </Dropdown>
    </Title>
  );
};
const loadingArray = [{}, {}, {}, {}, {}] as Data[];

const GoogleWidget: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [country, setCountry] = useState('US');

  useEffect(() => {
    Axios.get(`${googleApiUrl}/rss?geo=${country}`)
      .then(response => formatXMLtoJSON(response.data))
      .then(response => setData(orderBy(response, 'count', 'desc')));
  }, [country]);

  return (
    <Container title={<WidgetHeader setCountry={setCountry} />}>
      <List
        dataSource={data.length === 0 ? loadingArray : data}
        pagination={{ pageSize: 5 }}
        renderItem={item => (
          <Skeleton
            loading={data.length === 0}
            active
            paragraph={false}
            title={{ style: { padding: '8px 24px' } }}
          >
            <List.Item>
              <a
                href={`https://trends.google.com.br/trends/explore?q=${item.title}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>{item.title}</strong>
              </a>

              <span>{new Intl.NumberFormat('pt-BR').format(item.count)}</span>
            </List.Item>
          </Skeleton>
        )}
      />
    </Container>
  );
};

export default GoogleWidget;
