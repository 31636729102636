import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Skeleton } from 'antd';
import SectionTitle from 'components/SectionTitle';
import { Metric } from 'hooks/search';
import useDefaultChartsContainer from 'hooks/defaultChartsContainer';
import { Container, ChartsContainer } from './styles';
import SentimentChart from './SentimentChart';

const SentimentContainer: React.FC = () => {
  const intl = useIntl();
  const { paramsDatas, loading, searchedTerms } = useDefaultChartsContainer(
    '/historical_volume.json',
    {
      service: ['facebook', 'instagram', 'twitter'],
      grouping: 'sentiment',
    },
  );

  const ContainerBody = useCallback(props => {
    if (props.numberOfCharts && props.numberOfCharts > 1) {
      return <ChartsContainer>{props.children}</ChartsContainer>;
    }
    return <>{props.children}</>;
  }, []);

  return (
    <Container
      title={
        <SectionTitle>
          {intl.formatMessage({ id: 'results.sentiment' })}
        </SectionTitle>
      }
    >
      <ContainerBody numberOfCharts={paramsDatas?.size}>
        {paramsDatas &&
          paramsDatas.size >= 1 &&
          searchedTerms.map(item => {
            const key = item.obligatory;
            const value = paramsDatas.get(key) as Metric[];
            return value ? (
              <SentimentChart
                title={key}
                data={value}
                key={key}
                height={paramsDatas.size > 1 ? '250px' : undefined}
                width={paramsDatas.size > 1 ? '100%' : undefined}
              />
            ) : null;
          })}
        <Skeleton
          loading={loading}
          active
          title={false}
          paragraph={false}
          avatar={{
            size: paramsDatas && paramsDatas.size > 1 ? 180 : 250,
          }}
        />
      </ContainerBody>
    </Container>
  );
};

export default SentimentContainer;
