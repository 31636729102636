import React, { useMemo, useCallback, useState, useEffect } from 'react';
import pt from 'antd/es/date-picker/locale/pt_BR';
import en from 'antd/es/date-picker/locale/en_US';
import es from 'antd/es/date-picker/locale/es_ES';
import { useIntl } from 'react-intl';
import { useAuth } from 'hooks/auth';
import {
  format,
  startOfDay,
  parseISO,
  endOfDay,
  differenceInCalendarDays,
  isAfter,
  startOfTomorrow,
  isBefore,
  subYears,
  startOfToday,
} from 'date-fns';
import { useSearch } from 'hooks/search';
import ActiveCampaign from 'components/Modals/ActiveCampaign';

import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import { FormItem } from './styles';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = DatePicker; // import locale
interface Props {
  label: string;
}

// fixing antd locale bug
es.lang.locale = 'es';

const RangeDatePicker: React.FC<Props> = ({ label }) => {
  const intl = useIntl();
  const { user } = useAuth();
  const { params, setParams } = useSearch();
  const { language } = useAuth().user;
  const [activeCampaign, setActiveCampaign] = useState(false);

  const [dates, setDates] = useState<[string, string] | []>();
  const [hackValue, setHackValue] = useState<
    [Date | null, Date | null] | null
  >();
  const [value, setValue] = useState<[Date | null, Date | null] | null>();

  const isGuestUser = useMemo(() => {
    return !(
      user.consumer_type && user.consumer_type !== 'unassigned_costumer'
    );
  }, [user.consumer_type]);

  useEffect(() => {
    if (isGuestUser) {
      setActiveCampaign(true);
    }
    setValue([new Date(params.date.start), new Date(params.date.end)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locale = useMemo(
    () => ({
      pt,
      en,
      es,
    }),
    [],
  );

  const disabledDate = useCallback(
    (current: Date): boolean => {
      const afterTomorrow = isAfter(current, startOfTomorrow());
      const twoYearsLater = isBefore(current, subYears(startOfToday(), 2));

      if (!dates || dates.length === 0) {
        return afterTomorrow || twoYearsLater;
      }
      const dateA = new Date(dates[0]);
      const dateB = new Date(dates[1]);

      const afterRange = isGuestUser
        ? differenceInCalendarDays(dateA, current) > 30 ||
          differenceInCalendarDays(dateA, current) < -30 ||
          differenceInCalendarDays(dateB, current) > 30
        : differenceInCalendarDays(dateA, current) > 182 ||
          differenceInCalendarDays(dateA, current) < -182 ||
          differenceInCalendarDays(dateB, current) > 182;
      return afterTomorrow || twoYearsLater || afterRange;
    },
    [dates, isGuestUser],
  );

  const onOpenChange = useCallback((open: boolean): void => {
    if (open) {
      setHackValue([null, null]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  }, []);

  const handleDateSelect = useCallback(
    (_, date: [string, string]) => {
      if (date[0] === '' && date[1] === '') {
        return;
      }

      setValue([
        new Date(date[0].concat('T00:00-0800')),
        new Date(date[1].concat('T00:00-0800')),
      ]);
      const start = date[0];
      const end = date[1];

      const startParsed = format(
        startOfDay(parseISO(start)),
        'yyyy-MM-dd HH:mm:ss',
      );
      const endParsed = format(endOfDay(parseISO(end)), 'yyyy-MM-dd HH:mm:ss');

      setParams(prevParams => ({
        ...prevParams,
        date: { ...prevParams.date, start: startParsed, end: endParsed },
      }));
    },
    [setParams],
  );

  return (
    <FormItem label={label}>
      <div id="rangepicker" />
      <RangePicker
        disabled={isGuestUser}
        locale={locale['pt']}
        style={{ width: '100%' }}
        placeholder={[
          intl.formatMessage({ id: 'searchform.personalized.since' }),
          intl.formatMessage({ id: 'searchform.personalized.until' }),
        ]}
        onChange={handleDateSelect}
        value={hackValue || value}
        disabledDate={disabledDate}
        onCalendarChange={(_, date: [string, string]) => setDates(date)}
        onOpenChange={onOpenChange}
        getPopupContainer={(): HTMLElement => {
          return document.getElementById('rangepicker') as HTMLElement;
        }}
      />
      <ActiveCampaign
        visible={activeCampaign}
        onCancel={() => setActiveCampaign(false)}
      />
    </FormItem>
  );
};

export default RangeDatePicker;
