import { xml2js } from 'xml-js';

export default xml => {
  try {
    const response = xml2js(xml, {
      compact: true,
      spaces: 4,
    }).rss.channel.item;
    const responseData = response.map(item => ({
      title: item.title._text,
      count: parseInt(item['ht:approx_traffic']._text.replace(/,/g, '')),
    }));
    return responseData;
  } catch (err) {
    return new Error(err);
  }
};
