import React, { useMemo, useCallback, useState } from 'react';
import { Form, Input as InputANT, Tooltip, Modal, Table, Popover } from 'antd';
import { useIntl } from 'react-intl';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { InputProps } from 'antd/lib/input';

interface Props extends InputProps {
  label: string;
  booleanMode?: boolean;
}
const Input: React.FC<Props> = ({ label, booleanMode, ...rest }) => {
  const intl = useIntl();
  const [opened, setOpened] = useState(false);

  const booleanExplanation = useCallback(() => {
    setOpened(true);
    Modal.info({
      width: 650,
      icon: '',
      centered: true,
      title: intl.formatMessage({ id: 'boolean_operators.title' }),
      onOk: () => setOpened(false),
      content: (
        <Table
          dataSource={[1, 2, 3, 4].map(example => ({
            operator: intl.formatMessage({ id: `example${example}_operator` }),
            finds: intl.formatMessage({ id: `example${example}_finds` }),
          }))}
          columns={[
            {
              title: intl.formatMessage({ id: 'boolean_operators.operator' }),
              dataIndex: 'operator',
              key: 'operator',
            },
            {
              title: intl.formatMessage({ id: 'boolean_operators.finds' }),
              dataIndex: 'finds',
              key: 'finds',
            },
          ]}
          pagination={false}
        />
      ),
    });
  }, [intl]);

  const extraContent = useMemo(
    () =>
      booleanMode ? (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '2px 12px',
          }}
        >
          {intl.formatMessage({ id: 'boolean.tooltip' })}
          <QuestionCircleOutlined
            onClick={booleanExplanation}
            style={{ marginLeft: '8px' }}
          />
        </span>
      ) : (
        ''
      ),
    [booleanExplanation, booleanMode, intl],
  );

  return (
    <Form.Item label={label}>
      <Popover visible={booleanMode && !opened} content={extraContent}>
        <InputANT
          {...rest}
          placeholder={intl.formatMessage({ id: 'searchform.placeholder' })}
          suffix={[
            <Tooltip title={intl.formatMessage({ id: 'input.helper' })}>
              <InfoCircleOutlined />
            </Tooltip>,
          ]}
        />
      </Popover>
    </Form.Item>
  );
};

export default Input;
