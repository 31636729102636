import styled from 'styled-components';
import { Form } from 'antd';

export const FormItem = styled(Form.Item)`
  @media (max-width: 680px) {
    #rangepicker {
      .ant-picker-panels {
        flex-direction: column !important;
      }
    }
  }
`;
