import React, { useState, useCallback } from 'react';
import { Button, Form } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useSearch } from 'hooks/search';
import { InputANT } from './styles';

const AddTermButton: React.FC = () => {
  const intl = useIntl();
  const [isClicked, setIsClicked] = useState(false);
  const [term, setTerm] = useState('');
  const { addNewTermToSearch } = useSearch();

  const onSubmit = useCallback((): void => {
    if (term !== '') {
      addNewTermToSearch(term);
      setIsClicked(false);
      setTerm('');
    }
  }, [addNewTermToSearch, term]);

  return (
    <>
      {isClicked ? (
        <Form onFinish={onSubmit}>
          <InputANT
            onChange={e => setTerm(e.target.value)}
            placeholder={intl.formatMessage({
              id: 'results.comparison.input-placeholder',
            })}
          />
        </Form>
      ) : (
        <Button type="link" onClick={() => setIsClicked(!isClicked)}>
          <PlusCircleOutlined />
          {intl.formatMessage({
            id: 'results.comparison.button',
          })}
        </Button>
      )}
    </>
  );
};

export default AddTermButton;
