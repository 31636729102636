import React, { useEffect, useState, useCallback } from 'react';

import { useIntl } from 'react-intl';
import { useSearch, Metric, Params } from 'hooks/search';
import { Select, Skeleton } from 'antd';
import elasticsearchApi from 'services/elasticsearchApi';
import { sum, map, difference } from 'lodash';
import SectionTitle from 'components/SectionTitle';
import TermEvolutionWrapper from './TermEvolutionWrapper';
import { Container } from './styles';
import TotalBuzzChart from './TotalBuzzChart';

interface TermData {
  facebook: Metric[];
  twitter: Metric[];
  instagram: Metric[];
  totalPosts: number;
}

const TermEvolutionContainer: React.FC = () => {
  const intl = useIntl();
  const { queryBuilder, params, searchedTerms, comparisonTerms } = useSearch();
  const [loading, setLoading] = useState(false);
  const [totalPosts, setTotalPosts] = useState(0);
  const [paramsDatas, setParamsDatas] = useState<Map<string, TermData>>(
    new Map(),
  );
  const [total, setTotal] = useState(false);

  const [totalBuzz, setTotalBuzz] = useState<{
    perItem: Array<{ item: string; totalPerItem: number }>;
    total: number;
  }>({ perItem: [], total: 0 });

  useEffect(() => {
    if (searchedTerms.length < paramsDatas.size) {
      const keyToRemove = difference(
        Array.from(paramsDatas.keys()),
        map(searchedTerms, 'obligatory'),
      );
      setParamsDatas(prevDatas => {
        prevDatas.delete(keyToRemove?.[0]);
        return new Map(prevDatas);
      });
    }
  }, [paramsDatas, searchedTerms]);

  /*
    Updates the totalPosts state when paramDatas changes.
  */
  useEffect(() => {
    if (paramsDatas.size > 1) {
      const totals = searchedTerms.map(term => {
        const item = term.obligatory;
        const totalPerItem = paramsDatas.get(item)?.totalPosts || 0;
        return {
          item,
          totalPerItem,
        };
      });

      const totalSum = totals.reduce(
        (prev, acc) => prev + (acc.totalPerItem || 0),
        0,
      );
      setTotalBuzz({
        perItem: [...totals],
        total: totalSum,
      });
    }
  }, [paramsDatas, searchedTerms]);

  useEffect(() => {
    const allPosts =
      sum(Array.from(paramsDatas).map(([key, value]) => value.totalPosts)) || 0;
    setTotalPosts(allPosts);
  }, [paramsDatas]);

  const apiRequest = useCallback(
    async (term: Params) => {
      setLoading(true);
      await Promise.all([
        elasticsearchApi
          .post<{ data: Metric[] }>(
            '/historical_volume.json',
            queryBuilder({
              service: ['facebook'],
              grouping: 'period_daily',
              customParams: { ...term },
            }),
          )
          .then(response => {
            if (response?.data?.data && response.data.data.length > 0) {
              return response.data.data;
            }
            return [];
          }),
        elasticsearchApi
          .post<{ data: Metric[] }>(
            '/historical_volume.json',
            queryBuilder({
              service: ['instagram'],
              grouping: 'period_daily',
              customParams: { ...term },
            }),
          )
          .then(response => {
            if (response?.data?.data && response.data.data.length > 0) {
              return response.data.data;
            }
            return [];
          }),
        elasticsearchApi
          .post<{ data: Metric[] }>(
            '/historical_volume.json',
            queryBuilder({
              service: ['twitter'],
              grouping: 'period_daily',
              customParams: { ...term },
            }),
          )
          .then(response => {
            if (response?.data?.data && response.data.data.length > 0) {
              return response.data.data;
            }
            return [];
          }),
      ])
        .then(([facebook, instagram, twitter]) => {
          const allPosts =
            sum(map(facebook, 'value')) +
            sum(map(instagram, 'value')) +
            sum(map(twitter, 'value'));
          setParamsDatas(
            prevDatas =>
              new Map(
                prevDatas.set(term.obligatory, {
                  facebook,
                  twitter,
                  instagram,
                  totalPosts: allPosts,
                }),
              ),
          );
        })
        .finally(() => setLoading(false));
    },
    [queryBuilder],
  );

  /*
    Searches for the term set in the params.
  */
  const requestData = useCallback(() => {
    if (params.obligatory || params.optional || params.banned) {
      apiRequest(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  /*
    Calls requestData method always when it gets updated, which means that
    always when params changes requestData is called.
  */
  useEffect(() => {
    requestData();
  }, [requestData]);

  const validateTermData = useCallback((value: TermData): boolean => {
    if (
      !(value.facebook.length > 0) ||
      !(value.twitter.length > 0) ||
      !(value.instagram.length > 0)
    ) {
      return false;
    }
    return true;
  }, []);

  /*
    This effect is used to search for the comparison terms when the page is
    loaded for the first time.
  */
  useEffect(() => {
    if (comparisonTerms.length > 0) {
      comparisonTerms.forEach(term => {
        apiRequest(term);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonTerms]);

  return (
    <>
      {paramsDatas.size > 1 && (
        <Container
          style={{ marginBottom: '32px' }}
          title={[
            <header key="header">
              <SectionTitle>
                Total Buzz
                {/* {intl.formatMessage({ id: 'results.evolution' })} */}
              </SectionTitle>
            </header>,
          ]}
        >
          <TotalBuzzChart data={totalBuzz} />
        </Container>
      )}
      <Container
        title={[
          <header key="header">
            <SectionTitle>
              {intl.formatMessage({ id: 'results.evolution' })}
            </SectionTitle>
            <strong>
              {paramsDatas.size > 1
                ? ''
                : `${new Intl.NumberFormat('pt-BR').format(totalPosts)} posts`}
            </strong>
          </header>,
        ]}
      >
        {totalPosts > 0 && (
          <Select
            onChange={e => setTotal(!!e)}
            defaultValue={0}
            bordered={false}
            style={{ width: '100%' }}
          >
            <Select.Option value={1}>
              {intl.formatMessage({ id: 'terms-evolution.all' })}
            </Select.Option>
            <Select.Option value={0}>
              {intl.formatMessage({ id: 'terms-evolution.social' })}
            </Select.Option>
          </Select>
        )}
        {paramsDatas &&
          paramsDatas.size >= 1 &&
          searchedTerms.map(item => {
            const key = item.obligatory;
            const value = paramsDatas.get(key) as TermData;
            return value ? (
              <div key={`fragment-${key}`}>
                {value && validateTermData(value) && (
                  <TermEvolutionWrapper
                    title={key}
                    total={total}
                    key={key}
                    data={value}
                  />
                )}
              </div>
            ) : null;
          })}
        <Skeleton
          loading={loading}
          active
          title={false}
          paragraph={false}
          avatar={{
            shape: 'square',
            style: {
              height: 400,
              width: '100%',
            },
          }}
        />
      </Container>
    </>
  );
};

export default TermEvolutionContainer;
