import styled from 'styled-components';

import { Card } from 'antd';

export const Container = styled(Card)`
  grid-area: terms;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div.ant-skeleton-header {
    padding: 0;
  }
  @media (max-width: 650px) {
    div.ant-card-body {
      padding: 8px;
    }
  }
`;
