interface FormatQueryData {
  obligatory: string;
  optional: string;
  banned: string;
}

const booleanOperators = ['AND', '&&', 'OR', '||', 'NOT', '(', ')'];

const formatQuery = ({
  obligatory,
  optional,
  banned,
}: FormatQueryData): string => {
  let boolean = obligatory.match(
    /(")|(OR)|(AND)|(NOT)|(\()|(\))|(^(-[a-zA-Z0-9]*)$)/g,
  )
    ? obligatory
    : '';

  if (boolean) {
    boolean = boolean.trim().replace(/\(/g, '( ').replace(/\)/g, ' )');
    const booleanSplitted = boolean
      .split(/ +(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
      .filter(term => term !== '');

    const booleanFormatted = booleanSplitted.map(term => {
      if (!booleanOperators.includes(term) && !term.match('"')) {
        if (term.match(/^-[a-zA-Z0-9]/gi)) {
          return `NOT("${term.replace('-', '')}")`;
        }
        return `"${term}"`;
      }
      return term;
    });

    boolean = booleanFormatted.join(' ');
    boolean.replace(/""/g, '"');
  }

  const obligatoryQuery = obligatory
    .split(',')
    .map(term => term && `"${term.trim()}"`)
    .join(' ');
  const optionalString = optional
    .split(',')
    .map(term => term && `"${term.trim()}"`)
    .join(' OR ');

  const bannedQuery = banned
    .split(',')
    .map(term => term && `NOT("${term.trim()}")`)
    .join(' ');

  const optionalQuery = optionalString ? `( ${optionalString} )` : '';
  return `${boolean || obligatoryQuery} ${optionalQuery} ${bannedQuery}`.trim();
};

/*
  Format a FormatQueryData to a Influencers query string.
 */
export const formatInfluencersQuery = ({
  obligatory,
  optional,
  banned,
}: FormatQueryData): string => {
  let boolean = obligatory.match(/(")|(OR)|(AND)|(NOT)|(^(-[a-zA-Z0-9]*)$)/g)
    ? obligatory
    : '';

  if (boolean) {
    boolean = boolean.trim().replace(/\(/g, '( ').replace(/\)/g, ' )');

    const booleanSplitted = boolean
      .split(/ +(?=(?:(?:[^"]*"){2})*[^"]*$)/g)
      .filter(term => term !== '');

    const booleanFormatted = booleanSplitted.map(term => {
      if (!booleanOperators.includes(term) && !term.match('"')) {
        if (term.match(/^-[a-zA-Z0-9]/gi)) {
          return `NOT("${term.replace('-', '')}")`;
        }
      }
      return term;
    });

    boolean = booleanFormatted.join(' ');
  }

  const obligatoryQuery = obligatory
    .split(',')
    .map(term => term.trim())
    .join(' ');
  const optionalString = optional
    .split(',')
    .map(term => term.trim())
    .join(' OR ');
  const bannedQuery = banned
    .split(',')
    .map(term => term && `NOT(${term.trim()})`)
    .join(' ');

  const optionalQuery = optionalString ? `( ${optionalString} )` : '';
  return `${boolean || obligatoryQuery} ${optionalQuery} ${bannedQuery}`
    .trim()
    .replace(/"/g, '""');
};

export default formatQuery;
