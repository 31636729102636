import React, { useMemo, useCallback, memo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useIntl } from 'react-intl';
import { Metric } from 'hooks/search';
import { Empty } from 'antd';

interface GenderChartProps {
  data: Metric[];
  title?: string;
  width?: string;
  height?: string;
}

const GenderChart: React.FC<GenderChartProps> = ({
  data,
  title = '',
  width = '100%',
  height = '300px',
}) => {
  const intl = useIntl();

  const colors = useCallback((key: string) => {
    switch (key) {
      case 'FEMALE':
        return '#FFCAFF';
      case 'MALE':
        return '#92C8F9';
      default:
        return '#666';
    }
  }, []);

  const formattedData = useMemo(() => {
    return data && data.length > 0
      ? data
          .filter(
            gender => gender.label === 'MALE' || gender.label === 'FEMALE',
          )
          .map(gender => ({
            name: intl.formatMessage({
              id: `results.gender.${gender.label.toLowerCase()}`,
            }),
            value: gender.value,
            itemStyle: { color: colors(gender.label) },
          }))
      : [];
  }, [colors, data, intl]);

  return (
    <>
      {formattedData.length > 0 &&
      (formattedData[0]?.value !== 0 || formattedData[1]?.value !== 0) ? (
        <ReactEcharts
          style={{ height, width }}
          option={{
            title: {
              show: true,
              text: title,
              textStyle: {
                fontSize: 16,
              },
            },
            textStyle: {
              fontFamily: 'Lato',
            },
            tooltip: {
              trigger: 'item',
              formatter: '{b}: {c} ({d}%)',
            },
            legend: {
              type: 'plain',
              bottom: '0',
              padding: [10, 0],
            },
            toolbox: {
              show: true,
              right: 0,
              top: 20,
              feature: {
                saveAsImage: {
                  show: true,
                  title: intl.formatMessage({ id: 'toolbox.save' }),
                  name: 'data_buzzmonitor_trends',
                  excludeComponents: ['toolbox'],
                },
              },
            },
            series: [
              {
                type: 'pie',
                radius: ['0%', '75%'],
                avoidLabelOverlap: false,
                label: {
                  show: true,
                  fontWeight: 'bold',
                  position: 'inside',
                  formatter: '{d} %',
                },
                data: formattedData,
              },
            ],
          }}
        />
      ) : (
        <Empty
          description={intl.formatMessage({ id: 'posts.no-data-found' })}
        />
      )}
    </>
  );
};

const comparisonFn = (
  prevProps: GenderChartProps,
  newProps: GenderChartProps,
): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(newProps);
};

export default memo(GenderChart, comparisonFn);
