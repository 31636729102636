import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from 'pages/Home';
import Results from 'pages/Results';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/results" component={Results} exact />
    </Switch>
  );
};
export default Routes;
