import { Params, Language } from 'hooks/search';

interface URLParams extends Params {
  compare: string[];
}

function formatDate(date: string): string {
  // 2020 05 06 10 13 58
  const fdate: RegExpMatchArray | null = date.match(/.{1,2}/g);
  if (!fdate) return '';
  return `${fdate[0]}${fdate[1]}-${fdate[2]}-${fdate[3]} ${fdate[4]}:${fdate[5]}:${fdate[6]}`;
}

export default (searchData: string): URLParams => {
  const urlParams = new URLSearchParams(searchData);

  const obligatory = urlParams.get('obligatory') || urlParams.get('ob') || '';
  const optional = urlParams.get('optional') || urlParams.get('ops') || '';
  const banned = urlParams.get('banned') || urlParams.get('ba') || '';
  const compare: string[] = JSON.parse(urlParams.get('compare') || '[]');

  const languages: Language[] = JSON.parse(
    urlParams.get('languages') || '[]',
  ) as Language[];

  const date = {
    type: urlParams.get('dateType') || 'last30',
    start: urlParams.get('dateRange')
      ? JSON.parse(urlParams.get('dateRange') || '[]')[0]
      : formatDate(urlParams.get('initialDate') || ''),
    end: urlParams.get('dateRange')
      ? JSON.parse(urlParams.get('dateRange') || '[]')[1]
      : formatDate(urlParams.get('endDate') || ''),
  };

  const data = {
    obligatory,
    optional,
    banned,
    languages,
    date,
    compare,
  } as URLParams;
  return data;
};
