import React, { useState, useCallback, useEffect, memo } from 'react';
import { useIntl } from 'react-intl';
import { Button, Row, Col, Skeleton } from 'antd';
import { useAuth } from 'hooks/auth';
import ActiveCampaign from 'components/Modals/ActiveCampaign';

import influencersApi from 'services/influencersApi';
import { Container } from './styles';
import Post from './Post';

export interface SocialType {
  id: string;
  followers: number;
  social_network: string;
}

export interface InfluencerType {
  id?: string;
  name: string;
  photo: string;
  social: SocialType[];
  interests: string[];
  languages?: string[];
}

const DEFAULT_FILTERS = {
  searchFor: '',
  socialNetworks: '["instagram", "facebook", "twitter", "youtube"]',
  whereToSearch:
    '["username", "name", "top_hashtags", "top_terms", "top_entities", "top_mentions", "bio"]',
  min_followers: 1000,
  max_followers: 100000000,
  gender: '["M", "F", "ORG"]',
  languages: '[]',
  age: '[]',
  page: 0,
  countries: '[]',
  interests: '[]',
};

interface PostContainerProps {
  searchFor: string;
  languages: string[];
  title?: string;
}

const PostContainer: React.FC<PostContainerProps> = ({
  searchFor,
  languages,
  title = '',
}) => {
  const intl = useIntl();
  const { user } = useAuth();

  const [activeCampaign, setActiveCampaign] = useState(false);
  const [posts, setPosts] = useState<InfluencerType[]>([]);
  const [currentInfluencers, setCurrentInfluencers] = useState<
    InfluencerType[]
  >([]);
  const [pageRequest, setPageRequest] = useState(0);
  const [containerPage, setContainerPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const requestData = useCallback(() => {
    setLoading(true);
    influencersApi
      .get<{
        info: { profiles_found: number };
        data: Array<InfluencerType>;
      }>('/influencers/search', {
        params: {
          ...DEFAULT_FILTERS,
          searchFor,
          page: pageRequest,
          languages: [languages]
        },
      })
      .then(response => {
        if (response?.data?.data && response.data.data.length > 0) {
          setPosts(prevPosts => [...prevPosts, ...response.data.data]);
          if (response.data.data.length < 12) {
            setHasMore(false);
          }
        }
        setPageRequest(prevpageRequest => prevpageRequest + 1);
      })
      .finally(() => setLoading(false));
  }, [pageRequest, searchFor]);

  useEffect(() => {
    requestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (posts.length > 0 && currentInfluencers.length === 0) {
      setCurrentInfluencers([
        ...currentInfluencers,
        ...[...posts].slice(containerPage * 4, (containerPage + 1) * 4),
      ]);
      setContainerPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  const handleSeeMore = useCallback(() => {
    if (!user.consumer_type || user.consumer_type === 'unassigned_costumer') {
      return setActiveCampaign(true);
    }
    if (posts)
      setCurrentInfluencers([
        ...currentInfluencers,
        ...[...posts].slice(containerPage * 4, (containerPage + 1) * 4),
      ]);
    if (currentInfluencers.length === posts.length - 8) {
      requestData();
    }
    setContainerPage(containerPage + 1);
  }, [
    currentInfluencers,
    containerPage,
    posts,
    requestData,
    user.consumer_type,
  ]);

  return (
    <Container>
      <h5>{title}</h5>
      <Row gutter={[16, 8]}>
        {currentInfluencers.map((post, index) => (
          <Col xs={24} sm={12} lg={6} key={`col-${post.id}`}>
            <Post data={post} index={index + 1} key={post.id} />
          </Col>
        ))}
      </Row>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={false}
        avatar={{
          size: 180,
        }}
      />
      <Row justify="center">
        <Button shape="round" disabled={!hasMore} onClick={handleSeeMore}>
          {intl.formatMessage({ id: 'posts.button.seemore' })}
        </Button>
      </Row>
      <ActiveCampaign
        visible={activeCampaign}
        onCancel={() => setActiveCampaign(false)}
      />
    </Container>
  );
};

const comparisonFn = (
  prevProps: PostContainerProps,
  newProps: PostContainerProps,
): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(newProps);
};

export default memo(PostContainer, comparisonFn);
