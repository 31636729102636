import styled from 'styled-components';
import BrokenLink from 'assets/images/broken-link.png';
import { Divider } from 'antd';
import { Card } from 'components/SearchForm/styles';

export const Container = styled.div``;

export const Content = styled(Card).attrs({})`
  background-color: white;
  height: fit-content;
  width: 100%;
  max-width: 100%;
  .ant-card-cover {
    margin: 0;
  }
  .ant-card-body {
    padding: 12px;
  }
  color: #515151;
`;

export const PostImage = styled.div<{ url?: string; full?: boolean }>`
  background-image: url(${props => props.url || BrokenLink}), url(${BrokenLink});
  background-position: center center;
  background-size: cover;

  width: 100%;
  height: ${props => (props.full ? '352px' : '251.5px')};
  border-radius: 4px 4px 0 0;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  color: #fbb958;
  a {
    height: 22px;
    overflow: hidden;
    font-weight: bold;
    color: inherit;
  }
`;

export const Label = styled.span``;

export const SectionDivider = styled(Divider)`
  margin: 2px 0px 0px;
`;

export const TagContainer = styled.div`
  padding-bottom: 8px;
`;

export const TagGroup = styled.div`
  padding: 4px 0;

  .ant-tag {
    margin-bottom: 8px;
  }
`;

export const Social = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0;
  margin-top: 12px;
`;

export const SocialFollowers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.colors.tertiary};
  padding: 0 8px;
  svg {
    height: 20px;
    width: auto;
    margin-bottom: 4px;
    color: #d9d9d9;
  }
`;

export const SocialCount = styled.span`
  font-size: 11px;
  color: #515151;
`;

export const PopOverText = styled.span`
  font-size: 14px;
  color: #fbb958;
`;
