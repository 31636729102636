import React from 'react';

import styled from 'styled-components';

import BuzzmonitorLogo from 'assets/images/bm-logo.png';

const Header: React.FC = () => {
  return (
    <Container>
      <div>
        <a href="https://trends.app.buzzmonitor.com.br">
          <img src={BuzzmonitorLogo} alt="Buzzmonitor" />
        </a>
      </div>
    </Container>
  );
};

const Container = styled.header`
  max-width: 100vw;
  width: 100%;
  position: fixed;
  top: 0;
  height: 57px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  div {
    max-width: 1080px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      img {
        margin: 0 auto;
        height: 80px;
        width: auto;
      }
    }
    @media (max-width: 900px) {
      display: flex;
      img {
        margin: 0 auto;
      }
    }
    @media (max-width: 420px) {
      max-width: 100vw;
      button {
        font-size: 15px;
        width: 65px;
      }
      span {
        width: 0px;
      }
    }
  }
`;

export default Header;
