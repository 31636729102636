import React from 'react';
import { useIntl } from 'react-intl';
import { Skeleton } from 'antd';
import SectionTitle from 'components/SectionTitle';
import { Metric } from 'hooks/search';
import useDefaultChartsContainer from 'hooks/defaultChartsContainer';
import { Container } from './styles';
import SharingByNetworkChart from './SharingByNetworkChart';

const SharingByNetwork: React.FC = () => {
  const intl = useIntl();
  const { paramsDatas, loading, searchedTerms } = useDefaultChartsContainer(
    '/historical_volume.json',
    {
      grouping: 'service',
    },
  );

  return (
    <Container
      title={
        <SectionTitle>
          {intl.formatMessage({ id: 'results.representativeness' })}
        </SectionTitle>
      }
    >
      {paramsDatas &&
        paramsDatas.size >= 1 &&
        searchedTerms.map(item => {
          const key = item.obligatory;
          const value = paramsDatas.get(key) as Metric[];
          return value ? (
            <SharingByNetworkChart title={key} data={value} key={key} />
          ) : null;
        })}
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={false}
        avatar={{
          shape: 'square',
          style: {
            height: 400,
            width: '100%',
          },
        }}
      />
    </Container>
  );
};

export default SharingByNetwork;
