import React, { useCallback, useEffect, useState } from 'react';
import { Affix, Button, Space } from 'antd';
import { SearchOutlined, EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSearch } from 'hooks/search';
import {  BrowserView, MobileView } from "react-device-detect";
import { useIntl } from 'react-intl';
import SectionTitle from 'components/SectionTitle';
import { Container, ContainerAddTerm } from './styles';
import AddTermButton from './AddTermButton';
import TermCard from './TermCard';

const CardHeader: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { clearParams, resetParams } = useSearch();

  const handleNewSearch = useCallback(() => {
    clearParams();
    history.push('/');
  }, [clearParams, history]);

  const handleEditSearch = useCallback(() => {
    resetParams();
    history.push('/');
  }, [history, resetParams]);

  return (
    <header style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
      <SectionTitle>{intl.formatMessage({ id: 'results.title' })}</SectionTitle>
      <BrowserView style={{display: 'flex'}}>
        <Space direction="horizontal" align="center">
          <Button
            icon={<SearchOutlined />}
            type="primary"
            onClick={handleNewSearch}
            >
            {intl.formatMessage({ id: 'results.button.new' })}
          </Button>
          <Button icon={<EditOutlined />} onClick={handleEditSearch}>
            {intl.formatMessage({ id: 'results.button.modify' })}
          </Button>
        </Space>
      </BrowserView>
      <MobileView>
          <Space direction="horizontal" align="center">
            <Button
              icon={<SearchOutlined />}
              type="primary"
              shape="circle"
              onClick={handleNewSearch}/>
            <Button icon={<EditOutlined />} shape="circle" onClick={handleEditSearch}/>
        </Space>
      </MobileView>
    </header>
  );
};

const ResultsHeader: React.FC = () => {
  const intl = useIntl();
  const [resume, setResume] = useState(false);

  const {
    params,
    searchedTerms,
    removeTermFromSearch,
    editTermToSearch,
  } = useSearch();

  const handleScroll = useCallback(e => {
    if (window.pageYOffset > 100) {
      setResume(true);
    } else {
      setResume(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return function cleanupListener() {
      window.removeEventListener('resize', handleScroll);
    };
  }, [handleScroll]);

  const formatDate = useCallback((date: string) => {
    return date.split(' ')[0].split('-').reverse().join('/');
  }, []);

  return (
    <Affix offsetTop={0} style={{ width: '100%' }}>
      <Container title={<CardHeader />}>
        <div style={{ display: 'flex' }}>
          {searchedTerms.map(term => (
            <TermCard
              key={`term-card-${term.obligatory}`}
              term={term}
              remove={removeTermFromSearch}
              edit={editTermToSearch}
            />
          ))}
          {searchedTerms.length < 2 && (
            <ContainerAddTerm hoverable={false}>
              <AddTermButton />
            </ContainerAddTerm>
          )}
        </div>
        {!resume && (
          <Container.Grid hoverable={false} style={{ width: '100%' }}>
            {intl.formatMessage({ id: 'results.date.subtitle' })}
            <strong>
              {`${formatDate(params.date.start)} - ${formatDate(
                params.date.end,
              )}`}
            </strong>
          </Container.Grid>
        )}
      </Container>
    </Affix>
  );
};

export default ResultsHeader;
