import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100vh;
  }
  body {
    background: #f5f5f5;
    -webkit-font-smoothing: antialiased;
  }
  body, input,button {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
  }
  a {
       text-decoration: none;
  }
  ul {
      list-style: none;
  }
  button {
      cursor: pointer;
  }
  h1, h2,h3,h4,h5,h6 {
    margin: 0;
  }
  h1 {
    font-weight: 700;
    font-size: 43px;
  }
  h2 {
    font-weight: 700;
    font-size: 38px;
  }
  h3 {
    font-weight: 700;
    font-size: 34px;
  }
  h4 {
    font-weight: 700;
    font-size: 29px;
  }
  h5 {
    font-weight: 700;
    font-size: 25px;
  }
  h6 {
    font-weight: 700;
    font-size: 23px;
  }
  label {
    font-weight: 700;
    font-size: 15px;
  }
  p {
    font-weight: 400;
    line-height: 1.2rem;
    font-size: .9rem;
  }
  small {
    font-weight: 400;
    font-size: 12px;
  }
  div.ant-card, button {
    border-radius: 4px;
  }
  div.ant-card{
    border: 1px solid #e8e8e8;
  }
  button {
    svg {
      height: 0.8rem;
        width:auto;
    }
  }
  div.ant-card-head-title{
    padding: 8px 0;
  }
  @media (max-width: 480px){
    div.ant-card-head-title {
      img , svg {
        height: 1rem;
        width:auto;
      }
      h4 {
        font-size: 1.2rem;
      }
    }
    button {
      svg {
        height: 0.8rem;
          width:auto;
      }
    }
  }
`;
