import ReactEcharts from 'echarts-for-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

type TotalBuzzChartProps = {
  data: {
    perItem: Array<{ item: string; totalPerItem: number }>;
    total: number;
  };
  title?: string;
  width?: string;
  height?: string;
};

const TotalBuzzChart: React.FC<TotalBuzzChartProps> = ({
  data,
  title = '',
  width = '100%',
  height = '300px',
}) => {
  const intl = useIntl();

  const formattedData = useMemo(
    () =>
      data && data.perItem.length > 0
        ? data.perItem.map(item => {
            return {
              name: item.item,
              value: item.totalPerItem,
              // itemStyle: { color: colors(sentiment.label) },
            };
          })
        : [],
    [data],
  );

  return (
    <ReactEcharts
      style={{ height, width }}
      option={{
        title: {
          show: true,
          text: title,
          textStyle: {
            fontSize: 16,
          },
        },
        color: [
          '#5789ff',
          '#ff6b81',
          '#76b8ff',
          '#f58861',
          '#fedb51',
          '#665191',
          '#a05195',
        ],
        textStyle: {
          fontFamily: 'Lato',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)',
        },
        legend: {
          type: 'plain',
          bottom: '0%',
          itemWidth: 15,
        },
        toolbox: {
          show: true,
          right: 0,
          top: 20,
          feature: {
            saveAsImage: {
              show: true,
              title: intl.formatMessage({ id: 'toolbox.save' }),
              name: 'sentiments_buzzmonitor_trends',
              excludeComponents: ['toolbox'],
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['0%', '75%'],
            avoidLabelOverlap: false,
            label: {
              show: true,
              fontWeight: 'bold',
              position: 'inside',
              formatter: '{d} %',
            },
            labelLine: {
              show: false,
            },
            data: formattedData,
          },
        ],
      }}
    />
  );
};

export default TotalBuzzChart;
