import React, { useMemo, memo } from 'react';
import { useIntl } from 'react-intl';
import ReactEcharts from 'echarts-for-react';
import numeral from 'numeral';
import TrendsLogo from 'assets/images/trends-logo.png';
import { sum } from 'lodash';

interface EvolutionChartProps {
  XAxis: string[];
  title: string;
  legend: string[];
  data: Array<{
    name: 'Facebook' | 'X' | 'Instagram' | 'Total';
    value: number[] | undefined;
  }>;
}

const EvolutionChart: React.FC<EvolutionChartProps> = ({
  data,
  title,
  legend,
  XAxis,
}) => {
  const intl = useIntl();

  const colors: {
    [key in 'Facebook' | 'X' | 'Instagram' | 'Total']: string[];
  } = useMemo(
    () => ({
      Facebook: ['#C6D1E8', '#1877f2'],
      Instagram: ['#F0C0DE', '#CD2E92'],
      X: ['#CCCCCC', '#000000'],
      Total: ['#9a9a9a', '#444444'],
    }),
    [],
  );

  const magicTypeTranslations = useMemo(
    () => ({
      line: intl.formatMessage({ id: 'toolbox.line' }),
      bar: intl.formatMessage({ id: 'toolbox.bar' }),
    }),
    [intl],
  );
  const totalPosts = useMemo(() => {
    return new Intl.NumberFormat('pt-BR').format(
      data.reduce((acc, current) => {
        return acc + sum(current.value);
      }, 0),
    );
  }, [data]);

  return (
    <ReactEcharts
      ref={e => {
        return e?.getEchartsInstance().setOption({
          baseOption: {
            title: {
              show: true,
              text: title
                ? `${title} - ${totalPosts} ${intl.formatMessage({
                    id: 'results.representativeness.collected',
                  })}`
                : '',
              textStyle: {
                fontSize: 16,
              },
            },
            textStyle: {
              fontFamily: 'Lato',
            },
            grid: {
              top: 42,
              left: 60,
              right: 20,
              bottom: 65,
            },
            legend: {
              data: legend,
              top: 22,
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
            },
            graphic: {
              type: 'image',
              bottom: 0,
              left: 'center',
              right: 'center',
              style: {
                image: TrendsLogo,
                width: 244,
                height: 45,
              },
            },
            toolbox: {
              show: true,
              feature: {
                magicType: {
                  show: true,
                  type: ['line', 'bar'],
                  title: magicTypeTranslations,
                  option: {
                    bar: {
                      stack: 'social',
                    },
                    line: {
                      stack: false,
                    },
                  },
                },
                saveAsImage: {
                  show: true,
                  title: intl.formatMessage({ id: 'toolbox.save' }),
                  name: 'term_evolution_buzzmonitor',
                  excludeComponents: ['toolbox', 'dataZoom'],
                },
              },
            },
            brush: {
              xAxisIndex: 'all',
              brushLink: 'all',
              outOfBrush: {
                colorAlpha: 0.1,
              },
            },
            dataZoom: [{ type: 'slider', backgroundColor: '#fff' }],
            xAxis: {
              data: XAxis,
              type: 'category',
              boundaryGap: false,
              axisLine: {
                show: false,
              },
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: false,
              },
              axisLabel: {
                formatter: (value: number) => numeral(value).format('0.[0]a'),
              },
            },
            series: data.map(item => ({
              data: item.value,
              name: item.name,
              type: 'line',
              stack: false,
              smooth: true,
              symbolSize: 6,
              lineStyle: {
                color: colors[item.name][1],
                width: 2,
              },
              areaStyle: {
                color: colors[item.name][0],
                opacity: 0.4,
              },
              itemStyle: { color: colors[item.name][1] },
            })),
          },
          media: [
            {
              query: { maxWidth: 500 },
              option: {
                toolbox: {
                  show: false,
                },
                legend: {
                  textStyle: {
                    fontSize: 10,
                  },
                },
                graphic: {},
              },
            },
          ],
        });
      }}
      option={{}}
      style={{ height: '400px', width: '100%' }}
    />
  );
};

const comparisonFn = (
  prevProps: EvolutionChartProps,
  newProps: EvolutionChartProps,
): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(newProps);
};

export default memo(EvolutionChart, comparisonFn);
