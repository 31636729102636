import React, { useMemo, useCallback } from 'react';
import { Form, Select as SelectANT } from 'antd';
import { useIntl } from 'react-intl';
import { useSearch, DateType } from 'hooks/search';
import { format, subDays } from 'date-fns';

interface Option {
  value: string;
  name: string;
}
interface Props {
  label: string;
}

const Select: React.FC<Props> = ({ label }) => {
  const intl = useIntl();
  const { params, setParams } = useSearch();

  const options: Array<Option> = useMemo(() => {
    return [
      {
        value: 'today',
        name: intl.formatMessage({ id: 'searchform.personalized.today' }),
      },
      {
        value: 'last24',
        name: intl.formatMessage({ id: 'searchform.personalized.last24' }),
      },
      {
        value: 'last7',
        name: intl.formatMessage({ id: 'searchform.personalized.last7' }),
      },
      {
        value: 'last30',
        name: intl.formatMessage({ id: 'searchform.personalized.last30' }),
      },
      {
        value: 'personalized',
        name: intl.formatMessage({
          id: 'searchform.personalized.personalized',
        }),
      },
    ];
  }, [intl]);

  const handleDateChange = useCallback(
    (e: DateType) => {
      switch (e) {
        case 'today': {
          return setParams({
            ...params,
            date: {
              type: e,
              start: format(new Date(), 'yyyy-MM-dd 00:00:00').toString(),
              end: format(new Date(), 'yyyy-MM-dd hh:mm:ss').toString(),
            },
          });
        }
        case 'last24': {
          return setParams({
            ...params,
            date: {
              type: e,
              start: format(
                subDays(new Date(), 1),
                'yyyy-MM-dd hh:mm:ss',
              ).toString(),
              end: format(new Date(), 'yyyy-MM-dd hh:mm:ss').toString(),
            },
          });
        }
        case 'last7': {
          return setParams({
            ...params,
            date: {
              type: e,
              start: format(
                subDays(new Date(), 7),
                'yyyy-MM-dd hh:mm:ss',
              ).toString(),
              end: format(new Date(), 'yyyy-MM-dd hh:mm:ss').toString(),
            },
          });
        }
        case 'last30': {
          return setParams({
            ...params,
            date: {
              type: e,
              start: format(
                subDays(new Date(), 30),
                'yyyy-MM-dd hh:mm:ss',
              ).toString(),
              end: format(new Date(), 'yyyy-MM-dd hh:mm:ss').toString(),
            },
          });
        }
        default: {
          return setParams({
            ...params,
            date: {
              ...params.date,
              type: e,
            },
          });
        }
      }
    },
    [params, setParams],
  );

  return (
    <Form.Item label={label}>
      <SelectANT defaultValue={params.date.type} onChange={handleDateChange}>
        {options.map(({ value, name }) => (
          <SelectANT.Option value={value} key={value}>
            {name}
          </SelectANT.Option>
        ))}
      </SelectANT>
    </Form.Item>
  );
};

export default Select;
